import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import settings from "@iconify/icons-eva/settings-2-outline";
import logoutFill from "@iconify/icons-eva/log-out-fill";
import rooms from "@iconify/icons-eva/video-outline";

// ----------------------------------------------------------------------

export const getIcon = (name, size = 22) => (
  <Icon icon={name} width={size} height={size} />
);

const sidebarConfig = [
  {
    title: "live stream",
    path: "/b/dashboard/live-stream",
    icon: (
      <Icon icon="ic:round-live-tv" width={22} height={22} className="-mt-1" />
    ),
    collapse: false,
  },
  // {
  //   title: "profile",
  //   path: "/b/dashboard/profile",
  //   icon: (
  //     <Icon
  //       icon="healthicons:ui-user-profile"
  //       // className="-mt-1"
  //       width={22}
  //       height={22}
  //     />
  //   ),
  //   collapse: false,
  // },
  {
    title: "settings",
    path: "/b/dashboard",
    icon: <Icon icon="eva:settings-outline" width={22} height={22} />,
    collapse: false,
  },
  {
    title: "usage History",
    path: "/b/dashboard/usage-history",
    icon: (
      <Icon
        icon="fluent:data-usage-20-filled"
        width={22}
        height={22}
        className="-mt-1"
      />
    ),
    collapse: false,
  },
  {
    title: "Buy Credit",
    path: "/b/dashboard/buy-credit",
    icon: (
      // <Icon icon="cil:cart" />
      <Icon icon="cil:cart" width={22} height={22} className="-mt-1" />
    ),
    collapse: false,
  },
  {
    title: "contact us",
    path: "/b/dashboard/contact-us",
    icon: <Icon icon="ps:google-talk" width={22} height={22} className="" />,
    collapse: false,
  },
  // {
  //   title: "users",
  //   path: "/b/dashboard/user",
  //   icon: getIcon(peopleFill),
  // collapse:false
  // },
  // {
  //   title: "profile",
  //   path: "/b/dashboard/profile",
  //   icon: <Icon icon="healthicons:ui-user-profile" width={22} height={22} />,
  //   collapse: false,
  // },

  // {
  //   title: "rooms",
  //   path: "/b/dashboard/rooms",
  //   icon: <Icon icon="akar-icons:shipping-box-v2" width={22} height={22} />,
  //   collapse: false,
  // },

  // // {
  // //   title: "APIs",
  // //   path: "/b/dashboard/apis",
  // //   icon: <Icon icon="eos-icons:api-outlined" width={22} height={22} />,
  // //   collapse: false,
  // // },
  // {
  //   title: " streaming",
  //   path: "/b/dashboard/streaming",
  //   icon: <Icon width={22} height={22} icon="ic:baseline-live-tv" />,
  //   collapse: false,
  // },
  // {
  //   title: " recordings",
  //   path: "/b/dashboard/recordings",
  //   icon: getIcon(rooms),
  //   collapse: false,
  // },
  // {
  //   title: " settings",
  //   path: "/b/dashboard/settings",
  //   icon: <Icon icon="eva:settings-outline" width={22} height={22} />,
  //   collapse: false,
  // },
  {
    title: "logout",
    path: "/auth/login",
    icon: getIcon(logoutFill),
    collapse: false,
  },
];

export default sidebarConfig;
