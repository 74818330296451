// helper

export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const SHOW_SIDEBAR_NOT_FOR_INSIDE_DASHBOARD =
  "SHOW_SIDEBAR_NOT_FOR_INSIDE_DASHBOARD";
export const BACKDROP = "BACKDROP";

// auth
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const CLEAR_SIGN_UP_ERROR_MESSAGE = "CLEAR_SIGN_UP_ERROR_MESSAGE";
export const SIGN_UP_VERIFICATION_OTP = "SIGN_UP_VERIFICATION";
export const RESEND_SIGN_UP_VERIFICATION_OTP = "RESEND_SIGN_UP_VERIFICATION";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD_VERIFY_OTP = "RESET_PASSWORD_VERIFY_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_IMAGE = "UPDATE_USER_PROFILE_IMAGE";
export const GET_USER_PROFILE_IMAGE = "GET_USER_PROFILE_IMAGE";
export const VERIFY_UNIQUE_USER_ID = "VERIFY_UNIQUE_USER_ID";

// RTMP APIs
export const CREATE_RTMP_API = "CREATE_RTMP_API";
export const UPDATE_API = "UPDATE_API";
export const DELETE_API = "DELETE_API";
export const GET_MY_APIS = "GET_MY_APIS";

// RTMP APIs --> BBB SERVERS
export const GET_BBB_SERVERS = "GET_BBB_SERVERS";
export const ADD_BBB_SERVER = "ADD_BBB_SERVER";
export const UPDATE_BBB_SERVER = "UPDATE_BBB_SERVER";
export const DELETE_BBB_SERVER = "DELETE_BBB_SERVER";

// RTMP APIs --> RTMP CRED
export const ADD_RTMP_CRED = "ADD_RTMP_CRED";
export const UPDATE_RTMP_CRED = "UPDATE_RTMP_CRED";
export const DELETE_RTMP_CRED = "DELETE_RTMP_CRED";
export const GET_RTMP_CRED = "GET_RTMP_CRED";

// RTMP APIs --> RTMP CARDs
export const ADD_STREAMING_CARDS = "ADD_STREAMING_CARDS";
export const UPDATE_STREAMING_CARDS = "UPDATE_STREAMING_CARDS";
export const DELETE_STREAMING_CARDS = "DELETE_STREAMING_CARDS";
export const GET_STREAMING_CARDS = "GET_STREAMING_CARDS";
export const GET_SINGLE_STREAMING_CARD = "GET_SINGLE_STREAMING_CARD";

// get rooms
export const GET_ALL_RUNNING_ROOMS = "GET_ALL_RUNNING_ROOMS";

// GET one running streaming
export const GET_RUNNING_STREAMING = "GET_RUNNING_STREAMING";

//
export const START_STREAMING = "START_STREAMING";
export const STOP_STREAMING = "STOP_STREAMING";

// get past streaming
export const GET_PAST_STREAMING = "GET_PAST_STREAMING";

// user

// plans
export const CREATE_PLAN = "CREATE_PLAN";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const DELETE_PLAN = "DELETE_PLAN";
export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const GET_ONE_PLAN = "GET_ONE_PLAN";

// stripe
export const OPEN_STRIPE_BILLING_ADDRESS_PAGE =
  "OPEN_STRIPE_BILLING_ADDRESS_PAGE";
export const CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT";
export const RESET_PAYMENT_INTENT_CLIENT_SECRET =
  "RESET_PAYMENT_INTENT_CLIENT_SECRET";
export const LOCAL_RECHARGE_DETAILS = "LOCAL_RECHARGE_DETAILS";
export const RESET_LOCAL_RECHARGE_DETAILS = "RESET_LOCAL_RECHARGE_DETAILS";
export const RESET_LAST_RECHARGE = "RESET_LAST_RECHARGE";
export const STRIPE_BILLING_DETAILS = "STRIPE_BILLING_DETAILS";

export const GET_USD_CURRENCY_CONVERSION_RATE =
  "GET_USD_CURRENCY_CONVERSION_RATE";
