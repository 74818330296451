import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@iconify/react";
// import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { toast } from "react-toastify";

// actions
import {
  deleteRTMPCred,
  getRTMPCreds,
  deleteStremingCard,
  getStremingCard,
} from "../../../../../../redux/actions/RTMP-APIaction";
import { backdrop } from "../../../../../../redux/actions/helperAction";

import { useRedux } from "../../../../../../Hooks/useRedux";
import UpdateStreamingCard from "./UpdateStreamingCard";
import CustomDialog from "../../../../../../layouts/CustomDialog";
import Button from "../../../../../../layouts/Button";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus({ stream, d }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { dispatch } = useRedux();

  // update dialog
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const openUpdateDialogModal = () => {
    setOpenUpdateDialog(() => true);
  };
  const closeUpdateDialogModal = () => {
    setOpenUpdateDialog(() => false);
  };

  //  delete dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const openDeleteDialogModal = () => {
    setOpenDeleteDialog(() => true);
  };
  const closeDeleteDialogModal = () => {
    setOpenDeleteDialog(() => false);
  };

  // delete rtmp cred
  const handleDeleteStreamCard = () => {
    //  _id: req.body.id,
    dispatch(backdrop(true));
    dispatch(
      deleteStremingCard(
        { id: stream._id },
        {
          err: (m) => {
            toast.error(m);
            dispatch(backdrop(false));
          },
          done: (m) => {
            dispatch(
              getStremingCard({
                err: (m) => {
                  dispatch(backdrop(false));
                },
                done: (m) => {
                  dispatch(backdrop(false));
                  closeDeleteDialogModal();
                },
              })
            );
            toast.success(m);
          },
        }
      )
    );
  };

  return (
    <>
      <UpdateStreamingCard
        open={openUpdateDialog}
        closeDialog={closeUpdateDialogModal}
        stream={stream}
      />

      <CustomDialog
        className=""
        open={openDeleteDialog}
        title="Delete Streaming Channel"
        info={
          <span>Are you sure, want to delete this streaming channel ?</span>
        }
        button={
          <div className="flex justify-between items-center mt-4">
            <Button onClick={closeDeleteDialogModal}>Cancel</Button>
            <Button
              variant="primary"
              // className="bg-red-200 text-red-500 hover:bg-red-200 hover:text-red-600"
              className="bg-red-400 hover:bg-red-500"
              onClick={handleDeleteStreamCard}
            >
              Delete
            </Button>
          </div>
        }
      />

      <div>
        <span className="bg-gray-50 w-8 h-8 flex justify-center items-center rounded-full transition-all hover:bg-gray-100 hover:text-gray-600 hover:scale-110 cursor-pointer hover:ring-gray-400 origin-center">
          <Icon
            icon="eva:more-vertical-outline"
            onClick={handleClick}
            width={22}
          />
        </span>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              openUpdateDialogModal();
              handleClose();
            }}
            disableRipple
          >
            <div className="flex items-center gap-4">
              <Icon
                icon="akar-icons:chat-edit"
                width={22}
                // className="text-orange-400"
              />
              <span>Update</span>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              openDeleteDialogModal();
              handleClose();
            }}
            disableRipple
          >
            <div className="flex items-center gap-4">
              <Icon
                icon="eva:trash-2-fill"
                width={22}
                className="text-red-400"
              />
              <span className="text-red-500">Delete</span>
            </div>
          </MenuItem>

          {/* <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          More
        </MenuItem> */}
        </StyledMenu>
      </div>
    </>
  );
}
