import React from "react";
import { Helmet } from "react-helmet";
import propTypes from "prop-types";

const MetaDecorator = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default MetaDecorator;

MetaDecorator.prototype = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
};
