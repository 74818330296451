import { useDispatch, useSelector } from "react-redux";

export const useRedux = (queries = []) => {
  const dispatch = useDispatch();

  const data = {};

  const state = useSelector((state) => state);

  queries &&
    Array.isArray(queries) &&
    queries.length > 0 &&
    queries.map((q) => {
      if (q === "state") {
        data[q] = state;
      }
      data[q] = state[q];
      return null;
    });

  return { data, dispatch };
};
