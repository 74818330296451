import {
  CREATE_RTMP_API,
  DELETE_API,
  GET_MY_APIS,
  UPDATE_API,
  GET_BBB_SERVERS,
  GET_RTMP_CRED,
  GET_ALL_RUNNING_ROOMS,
  GET_STREAMING_CARDS,
  GET_RUNNING_STREAMING,
  GET_PAST_STREAMING,
  GET_SINGLE_STREAMING_CARD,
} from "../types";

// getting apis
export const getAPIS = (state = null, action) => {
  switch (action.type) {
    case GET_MY_APIS: {
      return action.payload;
    }
    // case UPDATE_API: {
    //   return null;
    // }
    // case DELETE_API: {
    //   return null;
    // }
    // case CREATE_RTMP_API: {
    //   return null;
    // }

    default: {
      return state;
    }
  }
};

// get bbb servers
export const getBBBServers = (state = null, action) => {
  switch (action.type) {
    case GET_BBB_SERVERS: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// RTMP Creds
export const getRTMPCreds = (state = null, action) => {
  switch (action.type) {
    case GET_RTMP_CRED: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// get running meeting rooms
export const getRunningRooms = (state = null, action) => {
  switch (action.type) {
    case GET_ALL_RUNNING_ROOMS: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// get streaming cards
export const getStreamingCards = (state = null, action) => {
  switch (action.type) {
    case GET_STREAMING_CARDS: {
      return action.payload;
    }
    case GET_SINGLE_STREAMING_CARD: {
      const streamingCards =
        Array.isArray(state) &&
        state.map((st) => {
          if (st._id === action.payload._id) {
            return action.payload;
          } else {
            return st;
          }
        });
      return streamingCards;
    }

    default: {
      return state;
    }
  }
};

export const getRunningStreaming = (state = null, action) => {
  switch (action.type) {
    case GET_RUNNING_STREAMING: {
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
};

export const getPastStreamings = (state = null, action) => {
  switch (action.type) {
    case GET_PAST_STREAMING: {
      return action.payload?.pastStreamings;
    }

    default: {
      return state;
    }
  }
};
