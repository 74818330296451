import React from "react";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import Logo from "../../../layouts/Logo";
// actions
import { showSidebar } from "../../../redux/actions/helperAction";

import { useRedux } from "../../../Hooks/useRedux";

const SidebarNav = () => {
  const {
    data: { sidebar },
    dispatch,
  } = useRedux(["sidebar"]);

  return (
    <div className="relative flex items-center justify-between">
      {/* Logo */}
      <div
        className={
          (clsx("transition-all"),
          sidebar
            ? "md:static"
            : "transition-all md:absolute md:left-52 opacity-0")
        }
      >
        <Logo to="/" className="w-[2.5rem] ml-5" />
      </div>

      {/* menu icon */}
      <div
        className={clsx(
          "xs:hidden md:block mr-5 cursor-pointer rotate-180 origin-center",
          sidebar ? "" : "ml-5"
        )}
        onClick={() => {
          dispatch(showSidebar(!sidebar));
        }}
      >
        <Icon icon="eva:menu-2-outline" width={26} height={26} />
      </div>
    </div>
  );
};

export default SidebarNav;
