import React from "react";
import { Icon } from "@iconify/react";
import { Outlet } from "react-router-dom";
import clsx from "clsx";

// actions
import { showSidebar } from "../../redux/actions/helperAction";

import Sidebar from "./Sidebar";

import { useRedux } from "../../Hooks/useRedux";
import Navbar, { NavBrand, NavItem, NavItemButton } from "../../layouts/Navbar";
import Logo from "../../layouts/Logo";

const DashboardLayout = () => {
  const {
    data: {
      sidebar,
      user: { user },
    },
    dispatch,
  } = useRedux(["sidebar", "user"]);

  return (
    <Navbar
      navBrand={
        !sidebar ? (
          <NavBrand>
            <Logo className="w-[2.5rem]" />
          </NavBrand>
        ) : (
          <div></div>
        )
      }
    >
      <NavItem className="text-primary-200 ">
        <div className="flex items-center gap-2">
          <h1 className="text-secondary-200 text-lg font-medium">Credit :</h1>
          <span>
            <b className="text-lg text-green-600">
              {parseFloat(Number(user?.streamingCraditHours)).toFixed(2)}{" "}
              min&nbsp;
            </b>
          </span>
        </div>
      </NavItem>
      <NavItem className="text-primary-200 md:hidden">
        <Icon
          className="cursor-pointer"
          icon="eva:menu-outline"
          height={26}
          width={26}
          onClick={() => {
            dispatch(showSidebar(!sidebar));
          }}
        />
      </NavItem>
    </Navbar>
  );
};

export default DashboardLayout;
