import {
  CREATE_PAYMENT_INTENT,
  OPEN_STRIPE_BILLING_ADDRESS_PAGE,
  LOCAL_RECHARGE_DETAILS,
  RESET_LOCAL_RECHARGE_DETAILS,
  RESET_PAYMENT_INTENT_CLIENT_SECRET,
  STRIPE_BILLING_DETAILS,
  GET_USD_CURRENCY_CONVERSION_RATE,
} from "../types";

// local recharge details
export const localRechargeDetails = (state = null, action) => {
  switch (action.type) {
    case LOCAL_RECHARGE_DETAILS: {
      return action.payload;
    }
    case RESET_LOCAL_RECHARGE_DETAILS: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// billing address page for stripe payment
export const openBillingAddressPage = (state = false, action) => {
  switch (action.type) {
    case OPEN_STRIPE_BILLING_ADDRESS_PAGE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// stripe payment intent
export const createStripePaymentIntent = (state = null, action) => {
  switch (action.type) {
    case CREATE_PAYMENT_INTENT: {
      return action.payload?.clientSecret;
    }
    case RESET_PAYMENT_INTENT_CLIENT_SECRET: {
      return null;
    }

    default: {
      return state;
    }
  }
};

// stripe billing details
const STRIPE_BILLING_DETAILS_DATA = {
  updateDetails: false,
  saveDetails: false,
  hasDetails: false,
  name: null,
  email: null,
  phone: null,
  line1: null,
  line2: null,
  postal_code: null,
  city: null,
  state: null,
  country: null,
};
export const stripeBillingDetails = (
  state = STRIPE_BILLING_DETAILS_DATA,
  action
) => {
  switch (action.type) {
    case STRIPE_BILLING_DETAILS: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

// currency conversion in usd
export const getCurencyConversionRateInUSD = (state = null, action) => {
  switch (action.type) {
    case GET_USD_CURRENCY_CONVERSION_RATE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
