import React from "react";
// components
import SidebarNavbar from "./SidebarNavbar";
import SidebarNavSection from "./SidebarNavSection";
import clsx from "clsx";
import { useRedux } from "../../../Hooks/useRedux";

const SidebarContainer = ({ children }) => {
  const {
    data: { sidebar },
  } = useRedux(["sidebar"]);
  return (
    <div
      className={clsx(
        "pt-3 border-r overflow-x-hidden overflow-y-auto max-h-full bg-white",
        !sidebar && "pt-5"
      )}
    >
      <SidebarNavbar />
      <SidebarNavSection />
    </div>
  );
};

export default SidebarContainer;
