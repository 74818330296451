import React from "react";
import "./styles.css";

const DotLoader = () => {
  return (
    <div className="dotloader__container">
      <div className="dotloader__yellow"></div>
      <div className="dotloader__red"></div>
      <div className="dotloader__blue"></div>
      <div className="dotloader__violet"></div>
    </div>
  );
};

export default DotLoader;
