import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { FormikProvider, Form, useFormik } from "formik";
import * as Yup from "yup";

import Button from "../../../../../../layouts/Button";
import CustomDialog from "../../../../../../layouts/CustomDialog";
import { useRedux } from "../../../../../../Hooks/useRedux";
import consoleHelper from "../../../../../../utils/consoleHelper";
import { backdrop } from "../../../../../../redux/actions/helperAction";
import {
  addRTMPCred,
  getRTMPCreds,
  getAllRunningRooms,
  addStreamingCard,
  getStremingCard,
} from "../../../../../../redux/actions/RTMP-APIaction";
import { toast } from "react-toastify";
import CustomInput from "../../../../../../layouts/CustomInput";
import { Icon } from "@iconify/react";

const AddRTMPCred = () => {
  const [openDialogModal, setOpenDialogModal] = useState(false);
  const openDialog = () => {
    setOpenDialogModal(() => true);
  };
  const closeDialog = () => {
    setOpenDialogModal(() => false);
  };

  const {
    data: { bbbServers, rtmpCreds, runningMeetings },
    dispatch,
  } = useRedux(["bbbServers", "rtmpCreds", "runningMeetings"]);

  const [extraFormData, setExtraFormData] = useState({
    showParticipantsList: false,
    showPublicChat: false,
    hidePresentation: false,
  });
  const handleChangeExtraFormData = (name, value) => {
    setExtraFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [BBBData, setBBBData] = useState(null);
  //   {
  //   bbbservername: "",
  //   bbburl: "",
  //   bbbsecret: "",
  // }
  const bbbArr =
    bbbServers && !bbbServers.err && Array.isArray(bbbServers)
      ? bbbServers.map((s) => ({ label: s.servername, value: s._id }))
      : [];
  const handleChangeBBBData = (id) => {
    consoleHelper("idddddddddd", id);
    const bbbServer =
      bbbServers &&
      !bbbServers.err &&
      Array.isArray(bbbServers) &&
      bbbServers.find((s) => s._id === id);

    setBBBData(() => {
      if (!id || typeof id !== "string" || id?.length < 1) {
        return null;
      }
      return {
        bbbservername: bbbServer.servername,
        bbburl: bbbServer.bbburl,
        bbbsecret: bbbServer.bbbsecret,
      };
    });
  };

  const [RTMPData, setRTMPData] = useState(null);
  //   {
  //   rtmpname: "",
  //   rtmpurl: "",
  //   rtmpsecret: "",
  // }
  const rtmpArr =
    rtmpCreds && !rtmpCreds.err && Array.isArray(rtmpCreds)
      ? rtmpCreds.map((r) => ({ label: r.rtmpname, value: r._id }))
      : [];
  const handleChangeRTMPData = (id) => {
    const rtmpCred =
      rtmpCreds &&
      !rtmpCreds.err &&
      Array.isArray(rtmpCreds) &&
      rtmpCreds.find((r) => r._id === id);

    setRTMPData(() => {
      if (!id || typeof id !== "string" || id?.length < 1) {
        return null;
      }
      return {
        rtmpname: rtmpCred.rtmpname,
        rtmpurl: rtmpCred.rtmpurl,
        rtmpsecret: rtmpCred.rtmpsecret,
      };
    });
  };

  useEffect(() => {
    if (
      bbbServers &&
      !bbbServers.err &&
      Array.isArray(bbbServers) &&
      bbbServers.length > 0
    ) {
      handleChangeBBBData(bbbServers[0]._id);
    }
  }, [bbbServers]);

  useEffect(() => {
    if (
      rtmpCreds &&
      !rtmpCreds.err &&
      Array.isArray(rtmpCreds) &&
      rtmpCreds.length > 0
    ) {
      handleChangeRTMPData(rtmpCreds[0]._id);
    }
  }, [rtmpCreds]);

  const [runningMeeting, setRunningMeeting] = useState(null);
  //   {
  //   runningmeetingName: "",
  //   runningMeetingId: "",
  // }
  let runningMeetingArr;
  if (
    runningMeetings &&
    !runningMeetings.err &&
    Array.isArray(runningMeetings)
  ) {
    runningMeetingArr = runningMeetings.map((m) => ({
      label: m.meetingName,
      value: m.meetingID,
    }));
  } else if (
    runningMeetings &&
    !runningMeetings.err &&
    typeof runningMeetings === "object" &&
    !Array.isArray(runningMeetings)
  ) {
    runningMeetingArr = [
      {
        label: runningMeetings.meetingName,
        value: runningMeetings.meetingID,
      },
    ];
  } else {
    runningMeetingArr = [
      // {
      //   label: "You have no running rooms",
      //   value: "You have no running rooms",
      // },
    ];
  }
  const handleChangeRunningMeetingData = (meetingID) => {
    let meeting;
    if (
      runningMeetings &&
      !runningMeetings.err &&
      Array.isArray(runningMeetings)
    ) {
      meeting = runningMeetings.find((m) => m.meetingID === meetingID);
    } else if (
      runningMeetings &&
      !runningMeetings.err &&
      typeof runningMeetings === "object" &&
      !Array.isArray(runningMeetings)
    ) {
      meeting = runningMeetings;
    } else {
      meeting = null;
    }

    setRunningMeeting(() => ({
      runningmeetingName: meeting?.meetingName,
      runningMeetingId: meeting?.meetingID,
    }));
  };

  const INITIAL_VALUES = {
    cardname: "",
    carddescription: "",
    duration: "",
  };

  const validationSchema = Yup.object().shape({
    cardname: Yup.string()
      .required("Card name is required !")
      // .matches(/^[0-9a-z]+$/, "Card name must be alpha numeric !")
      .min(3, "Card name must be minimum 3 character !")
      .max(50, "Card name must be maximum 50 character !"),
    carddescription: Yup.string()
      .max(200, "Card description max length must be 200 characters !")
      .nullable(),
    duration: Yup.number().required("Duration is required !"),
  });

  useEffect(() => {
    if (
      openDialogModal &&
      BBBData?.bbbservername?.length > 0 &&
      BBBData?.bbburl?.length > 0 &&
      BBBData?.bbbsecret?.length > 0
    ) {
      dispatch(backdrop(true));
      dispatch(
        getAllRunningRooms(
          { bbburl: BBBData?.bbburl, bbbsecret: BBBData?.bbbsecret },
          {
            err: (m) => {
              dispatch(backdrop(false));
              // toast.error(m);
              // toast.warning("Please Select Another BBB Server !");
            },
            done: (m) => {
              dispatch(backdrop(false));
            },
          }
        )
      );
    }
  }, [BBBData, openDialogModal]);

  const handleAddStreamingCard = (formData) => {
    console.log("skdfbchbvhwebhbh");
    if (!BBBData) {
      toast.warning("Please choose a BBB server");
      return;
    }
    if (!RTMPData) {
      toast.warning("Please choose a RTMP");
      return;
    }
    if (!runningMeetings || runningMeetings.err) {
      toast.error("You have no running meeting choosen !");
      return;
    }
    dispatch(backdrop(true));
    dispatch(
      addStreamingCard(
        {
          ...formData,
          ...extraFormData,
          ...BBBData,
          ...RTMPData,
          ...runningMeeting,
        },
        {
          err: (m) => {
            dispatch(backdrop(false));
            toast.error(m);
          },
          done: (m) => {
            //   dispatch(
            //     getRTMPCreds({
            //       err: (m) => {
            //         dispatch(backdrop(false));
            //       },
            //       done: (m) => {
            //         dispatch(backdrop(false));
            //         closeDialog();
            //       },
            //     })
            //   );
            // closeDialog();
            dispatch(getStremingCard({}));
            dispatch(backdrop(false));
            closeDialog();
            toast.success(m);
          },
        }
      )
    );
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,

    onSubmit: handleAddStreamingCard,
  });

  const { touched, errors, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <div>
        <Button
          variant="secondary"
          className="w-[116px]"
          onClick={openDialog}
          // startIcon={<Icon icon="carbon:add" />}
        >
          Create
        </Button>
      </div>

      <div>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <CustomDialog
              open={openDialogModal}
              info={false}
              // onClose={() => setOpenDialog(() => false)}
              title={<span className="">Add a New Stream </span>}
              button={
                <div className="mt-4 flex justify-between items-center">
                  <Button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={closeDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="secondary"
                    className=""
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </div>
              }
              className="w-[600px] min-w-[300px] divide-y-2 divide-gray-300"
            >
              <div className="py-4 space-y-5 max-h-[30rem] overflow-y-auto pr-2 pl-[2px] custom-scrollbar">
                <CustomInput
                  title="Stream Name"
                  className="w-full"
                  placeholder="Stream Name..."
                  autoFocus={true}
                  {...getFieldProps("cardname")}
                  error={Boolean(touched.cardname && errors.cardname)}
                  helperText={touched.cardname && errors.cardname}
                />
                <CustomInput
                  title="Stream Description"
                  className="w-full"
                  placeholder="Stream Description..."
                  {...getFieldProps("carddescription")}
                  error={Boolean(
                    touched.carddescription && errors.carddescription
                  )}
                  helperText={touched.carddescription && errors.carddescription}
                />
                <CustomInput
                  title="Maximum Duration"
                  className="w-full"
                  placeholder="Maximum Streaming Duration..."
                  type="number"
                  {...getFieldProps("duration")}
                  error={Boolean(touched.duration && errors.duration)}
                  helperText={touched.duration && errors.duration}
                />
                <CustomInput
                  selectInput
                  title="BigBlueButton Server"
                  className="w-full"
                  dataArr={[
                    { label: "choose a BBB server", value: "" },
                    ...bbbArr,
                  ]}
                  onChange={(e) => handleChangeBBBData(e.target.value)}
                />

                <CustomInput
                  title="Meeting Room"
                  className="w-full"
                  selectInput
                  dataArr={[
                    { label: "choose a running meeting room", value: "" },
                    ...runningMeetingArr,
                  ]}
                  onChange={(e) =>
                    handleChangeRunningMeetingData(e.target.value)
                  }
                />

                <CustomInput
                  title="RTMP Endpoint"
                  className="w-full"
                  selectInput
                  dataArr={[
                    { label: "Choose a RTMP Endpoint", value: "" },
                    ...rtmpArr,
                  ]}
                  onChange={(e) => handleChangeRTMPData(e.target.value)}
                />

                <div className="pt-1 space-y-5">
                  <div className="shadow-md py-2 rounded-md">
                    <SwitchInput
                      title="Show Participants List"
                      checked={extraFormData.showParticipantsList}
                      id="ssss"
                      onChange={(checked) => {
                        handleChangeExtraFormData(
                          "showParticipantsList",
                          checked
                        );
                      }}
                    />
                  </div>
                  <div className="shadow-md py-2 rounded-md">
                    <SwitchInput
                      title="Show Public Chat"
                      checked={extraFormData.showPublicChat}
                      onChange={(checked) => {
                        handleChangeExtraFormData("showPublicChat", checked);
                      }}
                    />
                  </div>
                  <div className="shadow-md py-2 rounded-md">
                    <SwitchInput
                      title="Hide Presentation"
                      checked={extraFormData.hidePresentation}
                      onChange={(checked) => {
                        handleChangeExtraFormData("hidePresentation", checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            </CustomDialog>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default AddRTMPCred;

const SwitchInput = ({
  checked,
  onChange,
  className,
  srOnly = "Use setting",
  id,
  title,
}) => {
  return (
    <div className="flex justify-between items-center">
      {title && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-primary-100 ml-1"
        >
          {title}
        </label>
      )}
      <Switch
        id={id}
        checked={checked}
        onChange={onChange}
        // className={`${checked ? "bg-teal-900" : "bg-teal-700"}
        //   relative inline-flex flex-shrink-0 h-[24px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${className}`}
        className={`${checked ? "bg-secondary-200" : "bg-secondary-50"}
          relative inline-flex flex-shrink-0 h-[24px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${className}`}
      >
        <span className="sr-only">{srOnly}</span>
        <span
          aria-hidden="true"
          className={`${checked ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
        />
      </Switch>
    </div>
  );
};
