import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import consoleHelper from "../../utils/consoleHelper";
import reducers from "../reducers";

consoleHelper(process.env.NODE_ENV);

const store =
  process.env.NODE_ENV === "development"
    ? createStore(
        reducers,
        {},
        compose(
          applyMiddleware(thunk),
          (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
            compose
        )
      )
    : createStore(reducers, {}, compose(applyMiddleware(thunk)));

export default store;
