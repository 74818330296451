import { memo, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import clsx from "clsx";

import Sidebar from "./Sidebar";
import DashboardNavbar from "./DashboardNavbar";

// actions
import { getProfile, logout } from "../../redux/actions/authAction";
import { showSidebar } from "../../redux/actions/helperAction";

import { useRedux } from "../../Hooks/useRedux";
import MainContentContainer from "./MainContent";
import consoleHelper from "../../utils/consoleHelper";
import Footer from "../Footer";

const DashboardLayout = () => {
  const navigate = useNavigate();

  const {
    data: {
      sidebar,
      user: { user },
    },
    dispatch,
  } = useRedux(["user", "sidebar"]);

  const sidebarRef = useRef(null);

  useEffect(() => {
    const clickAway = (e) => {
      if (
        sidebarRef?.current &&
        sidebar &&
        window.innerWidth <= 768 &&
        !sidebarRef.current.contains(e.target)
      ) {
        dispatch(showSidebar(false));
      }
    };

    document.addEventListener("click", clickAway);

    return () => {
      document.removeEventListener("click", clickAway);
    };
  }, [sidebar]);

  useEffect(() => {
    dispatch(
      getProfile({
        err: (m) => {
          dispatch(logout({}));
          localStorage.removeItem("dat");
          navigate("/auth/login");
        },
        done: (data) => {
          if (data.err) {
            dispatch(logout({}));
            localStorage.removeItem("dat");
            navigate("/auth/login");
          }
        },
      })
    );
  }, []);

  if (user === null) {
    return null;
  }

  return (
    <div className="w-screen h-screen flex relative">
      {/* sidebar */}
      <div
        className={clsx(
          "z-[8] xs:absolute xs:top-0 xs:bottom-0 xs:left-0 xs:w-[220px] md:relative lg:max-w-[274px] lg:w-[274px] md:max-w-[75px] overflow-hidden transition-all bg-white md:shadow-none",
          sidebar
            ? "transition-all md:max-w-[274px] md:w-[274px] xs:left-0 xs:shadow-xl"
            : "transition-all xs:transform xs:translate-x-[-274px] md:transform md:translate-x-[0px] md:max-w-[75px] lg:max-w-[75px] lg:w-[62.71px] md:w-[62.71px]"
        )}
        ref={sidebarRef}
      >
        <Sidebar />
      </div>

      {/* main content */}
      {/* main content */}
      <div className="w-full overflow-hidden flex flex-col">
        <MainContentContainer>
          <DashboardNavbar />
          <Outlet />
        </MainContentContainer>
      </div>
    </div>
  );
};

export default memo(DashboardLayout);
