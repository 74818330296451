import React from "react";
import { Navigate } from "react-router-dom";

const VerifyRoute = ({ component, v_key, v_value, redirectUrl }) => {
  if (localStorage.getItem(v_key) === v_value) {
    return component;
  } else {
    return <Navigate to={redirectUrl} replace />;
  }
};

export default VerifyRoute;
