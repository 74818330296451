import { useEffect, useState } from "react";
import CustomInput from "../../../../../layouts/CustomInput";
import CustomTab from "../../../../../layouts/Tab";
import PageLayout from "../PageLayout";

import StreamingCards from "./StreamingCards";
import { useRedux } from "../../../../../Hooks/useRedux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getBBBServers,
  getRTMPCreds,
  getStremingCard,
} from "../../../../../redux/actions/RTMP-APIaction";
import MetaDecorator from "../../../../../utils/MetaDecorator";
import { backdrop } from "../../../../../redux/actions/helperAction";
import RoadmapTimelinePopup from "./RoadmapTimelinePopup";

const LiveStream = () => {
  const navigate = useNavigate();

  const [rodmapDialog, setRoadmapDialog] = useState(true);
  function openRodmapDialog() {
    setRoadmapDialog(() => true);
  }
  function closeRodmapDialog() {
    setRoadmapDialog(() => false);
  }

  const {
    data: { bbbServers, rtmpCreds, streamingCards },
    dispatch,
  } = useRedux(["bbbServers", "rtmpCreds", "streamingCards"]);

  useEffect(() => {
    dispatch(getBBBServers({}));
  }, []);

  useEffect(() => {
    dispatch(getRTMPCreds({}));
  }, []);

  useEffect(() => {
    dispatch(getStremingCard({}));
  }, []);

  useEffect(() => {
    if (bbbServers === null || rtmpCreds === null) {
      dispatch(backdrop(true));
    } else {
      dispatch(backdrop(false));
    }
  }, [bbbServers, rtmpCreds]);

  // useEffect(() => {
  //   if (
  //     !bbbServers ||
  //     bbbServers?.err ||
  //     !Array.isArray(bbbServers) ||
  //     bbbServers.length === 0
  //   ) {
  //     navigate("/b/dashboard");
  //     toast.warning(
  //       "You don't have a BigBlueButton Server, Please Create a BigBlueButton Server First !"
  //     );
  //   }
  // }, [bbbServers]);

  // useEffect(() => {
  //   if (
  //     !rtmpCreds ||
  //     rtmpCreds?.err ||
  //     !Array.isArray(rtmpCreds) ||
  //     rtmpCreds.length === 0
  //   ) {
  //     navigate("/b/dashboard");
  //     toast.warning(
  //       "You don't have a RTMP Endpoint, Please Create a RTMP Endpoint First !"
  //     );
  //   }
  // }, [rtmpCreds]);

  if (bbbServers === null || rtmpCreds === null) {
    return null;
  }

  // if (
  //   (streamingCards === null || streamingCards?.err) &&
  //   (rtmpCreds === null ||
  //     rtmpCreds?.err ||
  //     !Array.isArray(rtmpCreds) ||
  //     rtmpCreds.length === 0 ||
  //     !bbbServers ||
  //     //   bbbServers?.err ||
  //     !Array.isArray(bbbServers) ||
  //     bbbServers.length === 0)
  // ) {
  //   return (
  //     <RoadmapTimelinePopup
  //       open={rodmapDialog}
  //       closeDialog={closeRodmapDialog}
  //     />
  //   );
  // }

  if (
    rtmpCreds === null ||
    rtmpCreds?.err ||
    !Array.isArray(rtmpCreds) ||
    rtmpCreds.length === 0 ||
    !bbbServers ||
    bbbServers?.err ||
    !Array.isArray(bbbServers) ||
    bbbServers.length === 0
  ) {
    return (
      <RoadmapTimelinePopup
        open={rodmapDialog}
        closeDialog={closeRodmapDialog}
      />
    );
  }

  return (
    <>
      <MetaDecorator
        title="Live Stream - BBBRoom Live"
        description="Usage - BBBRoom Live"
      />
      <PageLayout className="p-5">
        <div className="max-w-[1280px] mx-auto">
          <div className="space-y-10">
            {/* <div>
            <BBBServers />
          </div>

          <div>
            <RTMPCreds />
          </div> */}

            <div>
              <StreamingCards />
            </div>
          </div>

          {/* <CustomInput
          selectInput
          dataArr={arr}
          value={arr[0].label}
          className="w-full"
        /> */}
        </div>
      </PageLayout>
    </>
  );
};

export default LiveStream;
