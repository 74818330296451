import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";

export default function CustomDialog({
  title = "payment",
  info = "Your payment has been successfully submitted. We’ve sent you an email with all of the details of your order.",
  children,
  button = (
    <button
      type="button"
      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      // onClick={closeModal}
    >
      Got it, thanks!
    </button>
  ),
  open = false,
  onClose = () => {},
  className,
}) {
  if (!open) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-backdropBackground z-10 animate-dialog-background"></div>

      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-backdropBackground  animate-dialog-background"
          onClose={() => {}}
        >
          <div className="min-h-screen px-4 flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={clsx(
                  "p-6 text-left transition-all transform bg-white shadow-xl rounded-xl",
                  className
                )}
              >
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-primary-100"
                  >
                    {title}
                  </Dialog.Title>
                )}

                {info && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{info}</p>
                  </div>
                )}

                {children && <div className="mt-2">{children}</div>}

                {button && <div className="mt-4">{button}</div>}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
