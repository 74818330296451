import {
  CREATE_PLAN,
  UPDATE_PLAN,
  DELETE_PLAN,
  GET_ALL_PLANS,
  GET_ONE_PLAN,
} from "../types";

// get all plans
export const getAllPlans = (state = null, action) => {
  switch (action.type) {
    case GET_ALL_PLANS: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// get all plans
export const getOnePlan = (state = null, action) => {
  switch (action.type) {
    case GET_ONE_PLAN: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
