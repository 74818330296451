import { Icon } from "@iconify/react";
import React from "react";

const Footer = () => {
  return (
    <footer className="text-center bg-gray-50 text-gray-600">
      <div className="flex xs:flex-col xs:gap-5 md:flex-col md:items-center md:gap-5 1000px:flex-row 1000px:gap-0 1000px:justify-between lg:justify-between xl:justify-between  ">
        {/* <span>Get connected with us on social networks:</span> */}
        <span className="text-xs">
          Powered by{" "}
          <a
            href="https://bigbluebutton.host"
            target="_blank"
            rel="noreferrer"
            className="text-secondary-100 text-xs hover:underline cursor-pointer"
          >
            Bigbluebuttton Host{" "}
          </a>
        </span>

        <div className="xs:grid xs:grid-cols-1 place-items-center xs:gap-4 md:flex md:gap-4">
          {/* <div className="flex gap-4"> */}
          <div className="max-w-max ">
            <a
              href="https://bigbluebutton.host/managed-hosting"
              target="_blank"
              rel="noreferrer"
              className="transition-all cursor-pointer hover:underline text-gray-500"
            >
              Bigbluebutton Hosting
            </a>
          </div>
          <div className="max-w-max ">
            <a
              href="https://bigbluebutton.host/about"
              target="_blank"
              rel="noreferrer"
              className="transition-all cursor-pointer hover:underline text-gray-500"
            >
              About Us
            </a>
          </div>
          <div className="max-w-max ">
            <a
              href="https://bbbroom.tawk.help/"
              target="_blank"
              rel="noreferrer"
              className="transition-all cursor-pointer hover:underline text-gray-500"
            >
              Support
            </a>
          </div>
          <div className="max-w-max ">
            <a
              href="https://bigbluebutton.host/privacy-and-policy"
              target="_blank"
              rel="noreferrer"
              className="transition-all cursor-pointer hover:underline text-gray-500"
            >
              Privacy
            </a>
          </div>
          <div className="max-w-max ">
            <a
              href="https://bigbluebutton.host/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
              className="transition-all cursor-pointer hover:underline text-gray-500"
            >
              Terms & Conditions
            </a>
          </div>
        </div>

        <div className="flex justify-center">
          <a
            href="https://www.facebook.com/bigbluebuttonhost"
            target="_blank"
            rel="noreferrer"
            className="mr-6 text-gray-600"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="facebook-f"
              className="w-2.5"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/company/bigbluebuttonhost"
            target="_blank"
            rel="noreferrer"
            className="mr-6 text-gray-600"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="linkedin-in"
              className="w-3.5"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.youtube.com/channel/UCdIq97oMVPWQrHhrtbZnUsQ"
            target="_blank"
            rel="noreferrer"
            className="mr-6 mt-[2px] text-gray-600"
          >
            <Icon width={18} icon="fa:youtube-play" />
          </a>
        </div>
      </div>

      <div className="text-center p-1 pt-3">
        {/* <span>
          © {new Date().getFullYear()}{" "}
          <a
            href="/"
            className="transition-all text-secondary-100 cursor-pointer hover:underline hover:text-secondary-200"
          >
            Bymond Pvt. Ltd.
          </a>{" "}
          , Kolkata , India
        </span> */}
      </div>
    </footer>
  );
};

export default Footer;
