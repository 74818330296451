import React from "react";
import { Navigate } from "react-router-dom";

const TemporaryPrivetRoute = ({ component, _key, value, redirectUrl }) => {
  if (localStorage.getItem(_key) === value) {
    return component;
  } else {
    <Navigate to={redirectUrl} />;
  }
};

export default TemporaryPrivetRoute;
