import React, { useEffect } from "react";
import AddStreamingCard from "./AddStreamingCard";
import StreamingCardTable from "./StreamingCardTable";

import {
  getBBBServers,
  getRTMPCreds,
} from "../../../../../../redux/actions/RTMP-APIaction";
import { useRedux } from "../../../../../../Hooks/useRedux";

const StreamingCards = () => {
  const { dispatch } = useRedux();

  return (
    <div>
      <div className="flex justify-between items-center mb-3">
        <h1 className="text-xl font-semibold tracking-wide text-gray-500">
          Streaming Channels
        </h1>
        <AddStreamingCard />
      </div>
      <StreamingCardTable />
    </div>
  );
};

export default StreamingCards;
