import {
  SHOW_SIDEBAR,
  BACKDROP,
  SHOW_SIDEBAR_NOT_FOR_INSIDE_DASHBOARD,
} from "../types";

export const showSidebar = (open) => {
  return { type: SHOW_SIDEBAR, payload: open };
};

export const showHomeSidebar = (open) => {
  return { type: SHOW_SIDEBAR_NOT_FOR_INSIDE_DASHBOARD, payload: open };
};

// backdrop
export const backdrop = (open) => {
  return { type: BACKDROP, payload: { open } };
};
