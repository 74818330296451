import clsx from "clsx";
import React from "react";
import styles from "./styles.module.css";

const SpinLoader = () => {
  return (
    <div className="z-[999999999999999999999999999999999999]">
      <div
        className={clsx(
          styles.spinLoader,
          "z-[999999999999999999999999999999999999]"
        )}
      ></div>
    </div>
  );
};

export default SpinLoader;
