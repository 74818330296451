import { useRef, useEffect } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useRedux } from "../../Hooks/useRedux";

const TwakTo = () => {
  const {
    data: {
      user: { user },
    },
  } = useRedux(["user"]);

  console.log("onLoad twakto", { user });

  const tawkMessengerRef = useRef();
  const onLoad = () => {
    // Example for setting name and email

    tawkMessengerRef.current.setAttributes(
      {
        name: user?.fullname || null,
        email: user?.email || null,
        hash: user?.tawktoHashId || null,
      },
      function (error) {
        // do something if error
        // console.log("twatkto err", error);
      }
    );
  };

  useEffect(() => {
    const onLoad_ = () => {
      // Example for setting name and email

      tawkMessengerRef.current.setAttributes(
        {
          name: user?.fullname,
          email: user?.email,
          hash: user?.tawktoHashId,
        },
        function (error) {
          // do something if error
          // console.log("twatkto err", error);
        }
      );
    };

    tawkMessengerRef.current &&
      user?.fullname &&
      user?.email &&
      user?.tawktoHashId &&
      onLoad_();
  }, [user, tawkMessengerRef]);

  return (
    <TawkMessengerReact
      propertyId="62721611b0d10b6f3e7085ca"
      widgetId="1g26qce5b"
      //   propertyId="63cd43aec2f1ac1e202f0063"
      //   widgetId="1gnct0p3b"
      //
      onLoad={onLoad}
      ref={tawkMessengerRef}
    />
  );
};

export default TwakTo;
