import React, { forwardRef, Fragment } from "react";
import clsx from "clsx";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import { Icon } from "@iconify/react";

const CustomInputt = (
  {
    selectInput,
    checkbox,
    textarea,
    maxRows = 4,
    // fontSize = 17,
    placeholder,
    height = 100,
    width = "100%",
    padding = 20,
    checked,
    handleChecked,
    dataArr = [{ label: "examole..", value: 0 }],
    value,
    title,
    onChange,
    handleblur,
    name,
    required = false,
    error = false,
    helperText = "",
    type = "text",
    disabled,
    id,
    autoComplete = "off",
    className,
    autoFocus = false,
    htmlFor,
    startIcon,
    endIcon,
    startIconPointerEvents,
    endIconPointerEvents,
    inputPaddingLeft = "3rem",
    inputPaddingRight = "4rem",
    ...rest
  },
  ref
) => {
  let inputField;
  if (selectInput) {
    inputField = (
      <div>
        {title && (
          <label
            htmlFor={htmlFor}
            className="block text-sm font-medium text-primary-100"
          >
            {title}
          </label>
        )}

        <div className={clsx("mt-1", (startIcon || endIcon) && "relative")}>
          {/* start Icon */}
          {startIcon && (
            <span
              className="absolute top-1/2 -translate-y-1/2 ml-3"
              style={{
                pointerEvents: startIconPointerEvents && startIconPointerEvents,
              }}
            >
              {startIcon}
            </span>
          )}

          {/* End Icon */}
          {endIcon && (
            <span
              className="absolute top-1/2 right-0 -translate-y-1/2 mr-3"
              style={{
                pointerEvents: endIconPointerEvents && endIconPointerEvents,
              }}
            >
              {endIcon}
            </span>
          )}

          <select
            disabled={disabled}
            value={value}
            name={name}
            onChange={onChange}
            id={id}
            required={required}
            autoFocus={autoFocus}
            style={{
              paddingLeft: startIcon && inputPaddingLeft && inputPaddingLeft,
              paddingRight: endIcon && inputPaddingRight && inputPaddingRight,
              cursor: "pointer",
            }}
            className={clsx(
              "placeholder:text-gray-400",
              // startIcon && "pl-[" + inputPaddingLeft + "]",
              error && "border-danger border-2",
              className
            )}
            {...rest}
          >
            {dataArr.map((option, idx) => (
              <option
                key={option.value + "" + idx}
                value={option.value}
                style={{ cursor: "pointer" }}
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {helperText && helperText.length > 0 && (
          <span
            className={clsx("ml-2 text-muted text-xs", error && "text-red-500")}
          >
            {helperText}
          </span>
        )}
      </div>
    );
  } else if (checkbox) {
    inputField = (
      <div>
        {title && (
          <label
            htmlFor={htmlFor}
            className="block text-sm font-medium text-primary-100"
          >
            {title}
          </label>
        )}
        <div className={clsx("mt-1", (startIcon || endIcon) && "relative")}>
          <input
            disabled={disabled}
            className="cursor-pointer w-5 h-5 text-secondary-100"
            type="checkbox"
            id={id}
            name={name}
            value={value}
            checked={checked}
            placeholder={placeholder}
            onChange={onChange}
            {...rest}
          />
        </div>
        {helperText && helperText.length > 0 && (
          <span
            className={clsx("ml-2 text-muted text-xs", error && "text-red-500")}
          >
            {helperText}
          </span>
        )}
      </div>
    );
  } else if (textarea) {
    inputField = (
      <></>
      // <Box>
      //   {title && (
      //     <Box pb={1} pl={0.3}>
      //       <Typography
      //         color="textSecondary"
      //         style={{ fontSize: fontSize.medium }}
      //       >
      //         {title}
      //       </Typography>
      //     </Box>
      //   )}
      //   <TextareaAutosize
      //     // rowsMax={4}
      //     maxRows={maxRows}
      //     style={{
      //       height: height,
      //       width: width,
      //       padding: padding,
      //       fontSize: fontSize.medium,
      //     }}
      //     aria-label="maximum height"
      //     placeholder={placeholder}
      //     name={name}
      //     value={value}
      //     onChange={handleChange}
      //     {...rest}
      //   />
      //   {error && (
      //     <Typography
      //       color="error"
      //       style={{ fontSize: fontSize.small, marginLeft: 15 }}
      //     >
      //       {message}
      //     </Typography>
      //   )}
      // </Box>
    );
  } else {
    inputField = (
      <div>
        {title && (
          <label
            htmlFor={htmlFor}
            className="block text-sm font-medium text-primary-100"
          >
            {title}
          </label>
        )}
        <div className={clsx("mt-1", (startIcon || endIcon) && "relative")}>
          {/* start Icon */}
          {startIcon && (
            <span
              className="absolute top-1/2 -translate-y-1/2 ml-3"
              style={{
                pointerEvents: startIconPointerEvents && startIconPointerEvents,
              }}
            >
              {startIcon}
            </span>
          )}

          {/* End Icon */}
          {endIcon && (
            <span
              className="absolute top-1/2 right-0 -translate-y-1/2 mr-3"
              style={{
                pointerEvents: endIconPointerEvents && endIconPointerEvents,
              }}
            >
              {endIcon}
            </span>
          )}

          <input
            disabled={disabled}
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            type={type}
            autoComplete={autoComplete}
            required={required}
            autoFocus={autoFocus}
            onChange={onChange}
            style={{
              paddingLeft: startIcon && inputPaddingLeft && inputPaddingLeft,
              paddingRight: endIcon && inputPaddingRight && inputPaddingRight,
            }}
            className={clsx(
              "placeholder:text-gray-400",
              // startIcon && "pl-[" + inputPaddingLeft + "]",
              error && "border-danger border-2",
              className
            )}
            {...rest}
          />
        </div>
        {helperText && helperText.length > 0 && (
          <span
            className={clsx("ml-2 text-muted text-xs", error && "text-red-500")}
          >
            {helperText}
          </span>
        )}
      </div>
    );
  }
  return inputField;
};
const CustomInput = forwardRef(CustomInputt);
export default CustomInput;
