const consoleHelper = (fromComponent, data) => {
  if (process.env.NODE_ENV === "production") return;
  console.log("");
  console.log(`%c${fromComponent} :`, "color: neon; font-size: 18px");
  console.log(data);
  console.log("");
};

export default consoleHelper;

// '%cHello', 'color: green; background: yellow; font-size: 30px'
