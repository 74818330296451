import React, { useEffect } from "react";
import { useRedux } from "../../../../../../Hooks/useRedux";

import {
  TableContainer,
  Table,
  TableHead,
  TableHeading,
  TableRow,
  TableData,
  TableBody,
} from "../../../../../../layouts/Table";

import {
  getBBBServers,
  getRTMPCreds,
  getStremingCard,
} from "../../../../../../redux/actions/RTMP-APIaction";
import consoleHelper from "../../../../../../utils/consoleHelper";
import More from "./More";
import SingleStreamCard from "./SingleStreamCard";

/*
bbbsecret: "3LKCuzIEghM2LOTkXPdbN5s7QUvlcXiyMR0587NKSw"
bbbservername: "My Server 01"
bbburl: "https://bymond.live/bigbluebutton/api"
carddescription: "asjdnjbjk"
cardname: "Card01"
createdAt: "2022-02-21T16:15:08.168Z"
duration: 45
hidePresentation: false
liveStreamResponse: null
owneremail: "middyasahil00@gmail.com"
ownermongoid: "6203a93e1bd54456e0665d79"
rtmpname: "RTMP 01"
rtmpsecret: "asdwqdqwd"
rtmpurl: "https://www.figma.com/file/"
runningMeetingId: "OfR2h6tn6Oy83sJpVC"
runningmeetingName: "My room 2"
showParticipantsList: true
showPublicChat: true
streamingOutputURL: null
updatedAt: "2022-02-21T16:15:08.168Z"
__v: 0 */

const RTMPCredTable = () => {
  const {
    data: { streamingCards },
    dispatch,
  } = useRedux(["streamingCards"]);

  if (!streamingCards || streamingCards?.err) {
    return null;
  }

  return (
    <div>
      {/* <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading className="max-w-[4rem] w-16"></TableHeading>
              <TableHeading>RTMP Name</TableHeading>
              <TableHeading>RTMP URL</TableHeading>
              <TableHeading>RTMP Secret</TableHeading>
              <TableHeading>Created At</TableHeading>
              <TableHeading>Total Streaming</TableHeading>
              <TableHeading></TableHeading>
            </TableRow>
          </TableHead>

          <TableBody>
            {rtmpCreds?.map((rtmp, index) => {
              return (
                <TableRow className="text-xs" key={index}>
                  <TableData className="max-w-[4rem] w-16 px-0 flex justify-center items-center mt-1">
                    <b>{index + 1} .</b>
                  </TableData>
                  <TableData>{rtmp.rtmpname}</TableData>
                  <TableData>{rtmp.rtmpurl}</TableData>
                  <TableData>{rtmp.rtmpsecret}</TableData>
                  <TableData>
                    {new Date(rtmp.createdAt).toLocaleString()}
                  </TableData>
                  <TableData>{rtmp.totalstreaming}</TableData>
                  <TableData>
                    <More rtmp={rtmp} />
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
        {Array.isArray(streamingCards) &&
          streamingCards?.map((stream, idx) => {
            return <SingleStreamCard stream={stream} key={idx} />;
          })}
      </div>
    </div>
  );
};

export default RTMPCredTable;
