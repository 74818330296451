import axios from "../../apis/axios";

import {
  CREATE_RTMP_API,
  DELETE_API,
  GET_MY_APIS,
  UPDATE_API,

  // RTMP --> BBB server
  ADD_BBB_SERVER,
  UPDATE_BBB_SERVER,
  DELETE_BBB_SERVER,
  GET_BBB_SERVERS,

  // RTMP --> BBB server
  ADD_RTMP_CRED,
  UPDATE_RTMP_CRED,
  DELETE_RTMP_CRED,
  GET_RTMP_CRED,

  // RTMP --> Streaming Card
  ADD_STREAMING_CARDS,
  UPDATE_STREAMING_CARDS,
  DELETE_STREAMING_CARDS,
  GET_STREAMING_CARDS,
  GET_SINGLE_STREAMING_CARD,

  // get running rooms
  GET_ALL_RUNNING_ROOMS,

  // get one  running streaming
  GET_RUNNING_STREAMING,
  START_STREAMING,
  STOP_STREAMING,

  // get past streaming
  GET_PAST_STREAMING,
} from "../types";
import consoleHelper from "../../utils/consoleHelper";

export const createRTMP_API =
  ({ err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("/api/create-api-key");
      done && done(data);
      dispatch({ type: CREATE_RTMP_API, payload: data });
      // dispatch({
      //   type: GET_MY_APIS,
      //   payload: null,
      // });
    } catch (error) {
      dispatch({
        type: CREATE_RTMP_API,
        payload: { err: error?.rseponse?.data },
      });
      // dispatch({
      //   type: GET_MY_APIS,
      //   payload: { err: error?.rseponse?.data },
      // });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// get apis
export const getApis =
  ({ err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get("/user/get-my-apis", {
        withCredentials: true,
      });
      done && done(data);
      dispatch({ type: GET_MY_APIS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_MY_APIS,
        payload: { err: error?.rseponse?.data },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// update api
export const updateApi =
  (apiKey, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.put("/api/update-api-key", apiKey);
      dispatch({ type: UPDATE_API, payload: data });
      done && done(data?.msg);
    } catch (error) {
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// delete api
export const deleteApi =
  (id, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.delete("/api/delete-api-key", { data: id });
      dispatch({ type: DELETE_API, payload: data });
      done && done(data?.msg);
    } catch (error) {
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// RTMP --> BBB Servers

// add BBB serber
export const addBBBServers =
  (formData, { err, done }) =>
  async (dispatch) => {
    //const { servername, bbburl, bbbsecret } = req.body;
    try {
      const { data } = await axios.post(
        "/bbb-servers/add-bbb-server",
        formData
      );
      dispatch({ type: ADD_BBB_SERVER, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: ADD_BBB_SERVER,
        payload: { err: error?.response?.data },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// update BBB serber
export const updateBBBServers =
  (formData, { err, done }) =>
  async (dispatch) => {
    //  update-bbb-server;
    //  const { id, servername, bbburl, bbbsecret } = req.body;
    try {
      const { data } = await axios.put(
        "/bbb-servers/update-bbb-server",
        formData
      );
      dispatch({ type: UPDATE_BBB_SERVER, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: UPDATE_BBB_SERVER,
        payload: { err: error?.response?.data },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// delete BBB serber
export const deleteBBBServers =
  (formData, { err, done }) =>
  async (dispatch) => {
    //  update-bbb-server;
    //  _id: req.body.id,
    try {
      const { data } = await axios.delete("/bbb-servers/delete-bbb-server", {
        data: formData,
      });
      dispatch({ type: DELETE_BBB_SERVER, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: DELETE_BBB_SERVER,
        payload: { err: error?.response?.data },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// get BBB serber
export const getBBBServers =
  ({ err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get("/user/get-my-bbb-servers", {
        withCredentials: true,
      });
      dispatch({ type: GET_BBB_SERVERS, payload: data?.bbbservers });
      done && done();
    } catch (error) {
      dispatch({
        type: GET_BBB_SERVERS,
        payload: { err: error?.response?.data },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// RTMP --> RTMP CRED

// add RTMP CRED
export const addRTMPCred =
  (formData, { err, done }) =>
  async (dispatch) => {
    // const { rtmpname, rtmpurl, rtmpsecret } = req.body;
    try {
      const { data } = await axios.post("/rtmp-cred/add-rtmp-cred", formData);
      dispatch({ type: ADD_RTMP_CRED, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: ADD_RTMP_CRED,
        payload: {
          err: error?.response?.data?.msg || error?.response?.data?.err,
        },
      });
      err && err(error?.response?.data?.msg || error?.response?.data?.err);
    }
  };

// update RTMP CRED
export const updateRTMPCred =
  (formData, { err, done }) =>
  async (dispatch) => {
    // const { id, rtmpname, rtmpurl, rtmpsecret } = req.body;
    try {
      const { data } = await axios.put("/rtmp-cred/update-rtmp-cred", formData);
      dispatch({ type: UPDATE_RTMP_CRED, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: UPDATE_RTMP_CRED,
        payload: {
          err: error?.response?.data?.msg || error?.response?.data?.err,
        },
      });
      err && err(error?.response?.data?.msg || error?.response?.data?.err);
    }
  };

// delete RTMP CRED
export const deleteRTMPCred =
  (formData, { err, done }) =>
  async (dispatch) => {
    // const { id } = req.body;
    try {
      const { data } = await axios.delete("/rtmp-cred/delete-rtmp-cred", {
        data: formData,
      });
      dispatch({ type: DELETE_RTMP_CRED, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: DELETE_RTMP_CRED,
        payload: {
          err: error?.response?.data?.msg || error?.response?.data?.err,
        },
      });
      err && err(error?.response?.data?.msg || error?.response?.data?.err);
    }
  };

// get RTMP CRED
export const getRTMPCreds =
  ({ err, done }) =>
  async (dispatch) => {
    // const { id } = req.body;
    try {
      const { data } = await axios.get("/user/get-my-rtmp-creds", {
        withCredentials: true,
      });
      dispatch({ type: GET_RTMP_CRED, payload: data?.rtmpcreds });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: GET_RTMP_CRED,
        payload: {
          err: error?.response?.data?.msg || error?.response?.data?.err,
        },
      });
      err && err(error?.response?.data?.msg || error?.response?.data?.err);
    }
  };

// get all running rooms
export const getAllRunningRooms =
  (formData, { err, done }) =>
  async (dispatch) => {
    //  const { bbburl, bbbsecret } = req.body;
    try {
      const { data } = await axios.post(
        "/streaming/get-all-running-meetings",
        formData,
        { withCredentials: true }
      );
      dispatch({ type: GET_ALL_RUNNING_ROOMS, payload: data?.runningMeetings });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: GET_ALL_RUNNING_ROOMS,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// RTMP --> Streaming Card

// Add streaming card
export const addStreamingCard =
  (formData, { err, done }) =>
  async (dispatch) => {
    // const {
    //   cardname,
    //   carddescription,
    //   bbbservername,
    //   bbburl,
    //   bbbsecret,
    //   runningmeetingName,
    //   runningMeetingId,
    //   rtmpname,
    //   rtmpurl,
    //   rtmpsecret,
    //   duration,
    //   showParticipantsList,
    //   showPublicChat,
    //   hidePresentation,
    // } = req.body;
    try {
      const { data } = await axios.post(
        "/streaming/add-streaming-card",
        formData
      );

      dispatch({ type: ADD_STREAMING_CARDS, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: ADD_STREAMING_CARDS,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// update streaing card
export const updateStremingCard =
  (formData, { err, done }) =>
  async (dispatch) => {
    /*
     const {
        id,
        cardname,
        carddescription,
        rtmpname,
        rtmpurl,
        rtmpsecret,
        duration,
        showParticipantsList,
        showPublicChat,
        hidePresentation,
      } = req.body; */

    try {
      const { data } = await axios.put(
        "/streaming/update-streaming-card",
        formData
      );
      dispatch({ type: UPDATE_STREAMING_CARDS, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: UPDATE_STREAMING_CARDS,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// delete streaing card
export const deleteStremingCard =
  (formData, { err, done }) =>
  async (dispatch) => {
    /*
     const {
        id,
        cardname,
        carddescription,
        rtmpname,
        rtmpurl,
        rtmpsecret,
        duration,
        showParticipantsList,
        showPublicChat,
        hidePresentation,
      } = req.body; */

    try {
      const { data } = await axios.delete("/streaming/delete-streaming-card", {
        data: formData,
      });
      dispatch({ type: DELETE_STREAMING_CARDS, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: DELETE_STREAMING_CARDS,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// get streaing card
export const getStremingCard =
  ({ err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get("/user/get-my-streaming-cards", {
        withCredentials: true,
      });
      dispatch({ type: GET_STREAMING_CARDS, payload: data?.mystreamingCards });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: GET_STREAMING_CARDS,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// get one Running Streaming
export const getOneRunningStreaming =
  (formData, id, { err, done }) =>
  async (dispatch) => {
    // meetingId: req.body.runningMeetingId,runningMeetingId
    try {
      const { data } = await axios.post("user/get-my-one-streaming", formData);
      dispatch({ type: GET_RUNNING_STREAMING, payload: { [id]: data } });
      done && done();
    } catch (error) {
      dispatch({
        type: GET_RUNNING_STREAMING,
        payload: {
          [id]: {
            err: error?.response?.data?.err || error?.response?.data?.msg,
          },
        },
      });
      err && err();
    }
  };

// start Streaming
export const startStreaming =
  (formData, { err, done }) =>
  async (dispatch) => {
    // const {
    //   runningMeetingId,
    //   showParticipantsList,
    //   showPublicChat,
    //   hidePresentation,
    //   duration,
    // } = req.body;
    try {
      const { data } = await axios.post(
        "/local-live-streaming/start-local-rtmp-streaming",
        formData
      );
      consoleHelper("start streaming", data);
      dispatch({ type: START_STREAMING, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: START_STREAMING,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// stop streaming
export const stopStreaming =
  (formData, { err, done }) =>
  async (dispatch) => {
    //    const { runningMeetingId, streamId } = req.body;

    try {
      const { data } = await axios.post(
        "/local-live-streaming/end-local-rtmp-streaming",
        formData
      );
      consoleHelper("stop streaming", data);
      dispatch({ type: STOP_STREAMING, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        STOP_STREAMING,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// get Past Streaming
export const getPastStreaming =
  ({ err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get("/user/get-my-all-streamings");
      dispatch({ type: GET_PAST_STREAMING, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: GET_PAST_STREAMING,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// get single streaming card
export const singleStream =
  (formData, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/user/get-my-one-streaming-card",
        formData
      );

      dispatch({
        type: GET_SINGLE_STREAMING_CARD,
        payload: data?.mystreamingCard,
      });
      done && done();
    } catch (error) {
      dispatch({
        type: GET_SINGLE_STREAMING_CARD,
        payload: {
          err: error?.response?.data?.err || error?.response?.data?.msg,
        },
      });

      err && err();
    }
  };
