import React from "react";
import clsx from "clsx";

export const Table = ({ children, className }) => {
  return (
    <table className={clsx("min-w-full divide-y divide-gray-200", className)}>
      {children}
    </table>
  );
};

export const TableHead = ({ children, className }) => {
  return <thead className={clsx("bg-gray-50", className)}>{children}</thead>;
};

export const TableRow = ({ children, className }) => {
  return <tr className={clsx("", className)}>{children}</tr>;
};

export const TableHeading = ({ children, className }) => {
  return (
    <th
      scope="col"
      className={clsx(
        "px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
        className
      )}
    >
      {children}
    </th>
  );
};

export const TableBody = ({ children, className }) => {
  return (
    <tbody className={clsx("bg-white divide-y divide-gray-200", className)}>
      {children}
    </tbody>
  );
};

export const TableData = ({ children, className }) => {
  return (
    <td className={clsx("px-3 py-4 whitespace-nowrap", className)}>
      {children}
    </td>
  );
};

export const TableContainer = ({ children, className }) => {
  return (
    <div className={clsx("overflow-x-auto bg-white rounded-md", className)}>
      <div className="py-2 align-middle inline-block min-w-full">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          {children}
        </div>
      </div>
    </div>
  );
};

export const TablePagination = ({ className }) => {
  return (
    <div
      className={clsx(
        "px-3 bg-white py-4 whitespace-nowrap shadow-md rounded-r-lg rounded-l-lg"
      )}
    >
      ashcjh
    </div>
  );
};
