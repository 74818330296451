import axios from "axios";

export const url = (env) => {
  if (env === "development") {
    return process.env.REACT_APP_DEV_URL;
  }
  if (env === "production") {
    return process.env.REACT_APP_PROD_URL;
  }
};

axios.defaults.withCredentials = true;

export default axios.create({
  baseURL: url(process.env.NODE_ENV),
  withCredentials: true,
});
