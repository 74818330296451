import {
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_VERIFICATION_OTP,
  RESEND_SIGN_UP_VERIFICATION_OTP,
  LOGIN,
  CLEAR_SIGN_UP_ERROR_MESSAGE,
  FORGOT_PASSWORD,
  RESET_PASSWORD_VERIFY_OTP,
  RESET_PASSWORD,
  GET_PROFILE,
  UPDATE_USER_PROFILE,
  GET_USER_PROFILE_IMAGE,
  RESET_LAST_RECHARGE,
  CREATE_PAYMENT_INTENT,
} from "../types";

const INITIAL_STATE = {
  user: null,
  error: false,
  message: null,
  token: "",
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_UP: {
      return {
        ...state,
        message: action.payload,
        error: false,
        user: null,
        token: "",
      };
    }
    case SIGN_UP_VERIFICATION_OTP: {
      return {
        ...state,
        message: action.payload,
        error: false,
        user: null,
        token: "",
      };
    }
    case RESEND_SIGN_UP_VERIFICATION_OTP: {
      return {
        ...state,
        message: action.payload,
        error: false,
        user: null,
        token: "",
      };
    }
    case SIGN_UP_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        user: null,
        token: "",
      };
    }
    case CLEAR_SIGN_UP_ERROR_MESSAGE: {
      return {
        ...state,
        message: action.payload,
        error: false,
        user: null,
        token: "",
      };
    }
    case LOGIN: {
      return {
        ...state,
        message: null,
        user: action.payload.user,
        error: false,
        token: action.payload.token,
      };
    }
    case FORGOT_PASSWORD: {
      return {
        ...state,
        message: action.payload,
        error: false,
        user: null,
        token: "",
      };
    }
    case RESET_PASSWORD_VERIFY_OTP: {
      return {
        ...state,
        message: action.payload,
        error: false,
        user: null,
        token: "",
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        message: action.payload,
        error: false,
        user: null,
        token: "",
      };
    }
    case GET_PROFILE: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case UPDATE_USER_PROFILE: {
      return {
        ...state,
        user: null,
      };
    }
    case CREATE_PAYMENT_INTENT: {
      return {
        ...state,
        user: action.payload?.user,
      };
    }
    case RESET_LAST_RECHARGE: {
      return {
        ...state,
        user: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

// user profile image
export const getUserProfileImage = (state = null, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_IMAGE: {
      return action.payload;
    }
    case UPDATE_USER_PROFILE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
