import React from "react";
import { useNavigate } from "react-router-dom";

import sidebarData from "./sidebarData";
import NavItem from "./NavItem";
import { useRedux } from "../../../Hooks/useRedux";
import { logout } from "../../../redux/actions/authAction";
import { backdrop, showSidebar } from "../../../redux/actions/helperAction";

const SidebarNavSection = () => {
  const navigate = useNavigate();
  const { dispatch } = useRedux();

  const handleClick = (title, path) => {
    if (title === "logout") {
      dispatch(backdrop(true));
      dispatch(
        logout({
          err: (m) => {
            dispatch(backdrop(false));
            localStorage.clear();
            navigate("/");
          },
          done: (m) => {
            dispatch(backdrop(false));
            localStorage.clear();
            navigate("/");
          },
        })
      );
    } else {
      if (window.innerWidth < 768) {
        dispatch(showSidebar(false));
      }
      navigate(path);
    }
  };

  return (
    <div className="mt-20">
      {sidebarData.map((data) => (
        <NavItem
          icon={data.icon}
          title={data.title}
          key={data.path}
          path={data.path}
          collapse={data.collapse}
          onClick={() => handleClick(data.title, data.path)}
        />
      ))}
    </div>
  );
};

export default SidebarNavSection;
