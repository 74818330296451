import { useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

import { Icon } from "@iconify/react";

import consoleHelper from "../../../utils/consoleHelper";
import { getIcon } from "./sidebarData";
import { useRedux } from "../../../Hooks/useRedux";

// ----------------------------------------------------------------------

const NavItem = ({ icon, title, path, onClick, collapse, expanded }) => {
  const location = useLocation();

  const [expand, setExpand] = useState(false);

  // consoleHelper("location", location);

  const active = location.pathname === path;

  const {
    data: { sidebar },
    dispatch,
  } = useRedux(["sidebar"]);

  return (
    <div
      className={clsx(
        "flex justify-start items-center h-[50px] transition-colors hover:bg-gray-50 cursor-pointer",
        !collapse && active && " border-r-[4px] border-r-green-500 rounded-sm"
      )}
      onClick={() => {
        onClick();
        collapse && setExpand((prev) => !prev);
      }}
    >
      <div className="flex items-center gap-3 ml-5">
        <div
          className={clsx(
            "text-neutral-600 transition-all tex-xs xl:mr-0",
            !collapse && active && "text-midnight tex-sm xl:scale-100",
            sidebar && "mr-0",
            !sidebar && active && "md:scale-125 md:mr-5 xl:scale-125"
          )}
        >
          {icon}
        </div>
        <span
          className={clsx(
            "capitalize text-metal transition-all text-xs",
            !collapse && active && "text-[15px] font-semibold text-midnight",
            !sidebar && "hidden"
          )}
        >
          {title}
        </span>
      </div>
      {collapse && (
        <span
          className={clsx(
            "ml-auto mr-5 mt-[-5px] transition-transform",
            expand && "rotate-180"
          )}
        >
          <Icon icon="eva:arrow-ios-downward-outline" />
        </span>
      )}
    </div>
  );
};

//border-r-green-500

export default NavItem;
