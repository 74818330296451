import React from "react";
import { useRedux } from "../../Hooks/useRedux";
import consoleHelper from "../../utils/consoleHelper";

export const NavBrand = ({
  children,
  className,
  normalResponsiveBehavior = true,
}) => {
  const {
    data: { sidebar },
  } = useRedux(["sidebar"]);

  return (
    <div
      className={`pl-3 pr-3 ${
        normalResponsiveBehavior && sidebar
          ? "transition-all absolute top-[-500px] hidden"
          : ""
      } ${className}`}
    >
      <div className="w-[6rem] md:w-[7rem]">{children}</div>
    </div>
  );
};

export const NavItem = ({ children, className }) => {
  return <div className={`pl-3 pr-3 ${className}`}>{children}</div>;
};
export const NavItemButton = ({ children, className }) => {
  return <div className={`btn-primary ${className}`}>{children}</div>;
};

const Navbar = ({
  children,
  navBrand = <NavBrand />,
  className,
  childClassName,
}) => {
  //   consoleHelper("children", children);
  return (
    <div
      className={`w-full transition-all bg-white shadow-sm h-[58px] md:h-[64px] flex items-center justify-between ${className}`}
    >
      <div
        className={`w-full transition-all bg-white h-full flex items-center justify-between ${childClassName}`}
      >
        {navBrand}
        <div className="flex items-center">{children}</div>
      </div>
    </div>
  );
};

export default Navbar;
