import { useState } from "react";
import Button from "../../../../../layouts/Button";
import CustomDialog from "../../../../../layouts/CustomDialog";
import { Icon } from "@iconify/react";
import clsx from "clsx";
import { useRedux } from "../../../../../Hooks/useRedux";

import cssStyle from "./RoadmapTimelinePopup.module.css";
import { useNavigate } from "react-router-dom";

import { useSpring, animated } from "react-spring";

const RoadmapTimelinePopup = ({ open, closeDialog }) => {
  const navigate = useNavigate();
  const {
    data: { bbbServers, rtmpCreds, streamingCards },
    dispatch,
  } = useRedux(["bbbServers", "rtmpCreds", "streamingCards"]);

  const [showContent, setShowContent] = useState(1);

  const handleShowContent = () => {
    if (showContent === 1) {
      setShowContent(2);
    }
    if (showContent === 2) {
      setShowContent(1);
    }
  };

  //open animation with react spring

  const openAnimation1 = useSpring({
    from: { opacity: "0", maxHeight: "0px", overflow: "hidden" },
    to: {
      opacity: "1",
      maxHeight: showContent === 1 ? "300px" : "0px",
      overflow: showContent === 1 ? "visible" : "hidden",
    },
    config: { duration: "300" },
  });

  const openAnimation2 = useSpring({
    from: { opacity: "0", maxHeight: "0px", overflow: "hidden" },
    to: {
      opacity: "1",
      maxHeight: showContent === 2 ? "300px" : "0px",
      overflow: showContent === 2 ? "visible" : "hidden",
    },
    config: { duration: "300" },
  });

  //rotate animation
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#ffff",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#10d6f5" : "#fff",
    },
    config: { duration: "120" },
  });

  return (
    <CustomDialog
      className="max-w-[700px]"
      title="Configure your BBB Server & RTMP Endpoints : )"
      // info="Now you need to arrange a little information to Get Started !!!"
      info=""
      open={open}
      button={
        <div className="flex items-center justify-between">
          <div />
          <Button
            variant="secondary"
            onClick={() => {
              navigate("/dashboard");
            }}
            className="min-w-[116px]"
          >
            Set Up Now
          </Button>
        </div>
      }
    >
      <div className="xs:pl-3 450px:pl-3 550px:pl-6 pt-3 h-[70vh] overflow-y-auto">
        <ol className="relative border-l border-gray-200 dark:border-gray-700">
          <li className={clsx("mb-10 ml-6", showContent === 2 && "")}>
            {Array.isArray(bbbServers) && bbbServers.length > 0 ? (
              <span className="flex absolute -left-3 justify-center items-center w-7 h-7 bg-green-100 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <Icon
                  width={30}
                  className="text-green-500 w-[100px]"
                  icon="typcn:tick"
                />
              </span>
            ) : (
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-white rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <Icon
                  width={26}
                  className="text-red-400"
                  icon="subway:missing"
                />
              </span>
            )}

            <div className="flex xs:flex-col xs:justify-start items-start 680px:flex-row 680px:justify-between 680px:items-start mb-0">
              <h3
                onClick={() => setShowContent(1)}
                className={clsx(
                  "cursor-pointer flex items-center mb-1 font-semibold dark:text-white tracking-[0.2px] text-xl",
                  "transition-all text-gray-700/70 hover:text-gray-800/90",
                  showContent === 1 && "transition-all text-gray-800/90"
                )}
              >
                <span>Your Bigbluebutton Server API URL & Secret</span>
                <span>
                  <Icon
                    icon="eva:arrow-ios-forward-outline"
                    width={26}
                    className={clsx(
                      "font-bold -mt-[1px] ml-3",
                      showContent === 1 ? "rotate-90" : ""
                    )}
                  />
                </span>
              </h3>
            </div>

            <animated.p
              className={clsx(
                "mb-4 text-base font-normal text-gray-500 dark:text-gray-400"
              )}
              style={openAnimation1}
            >
              If you don’t have this information saved or this is out of your
              knowledge, but you own the BigBlueButton server , you may contact
              us to retrieve the API credentials for you from your BigBlueButton
              Server .
            </animated.p>
          </li>

          {/* second */}
          <li className="mb-10 ml-6">
            {Array.isArray(rtmpCreds) && rtmpCreds.length > 0 ? (
              <span className="flex absolute -left-3 justify-center items-center w-7 h-7 bg-green-100 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <Icon
                  width={30}
                  className="text-green-500 w-[100px]"
                  icon="typcn:tick"
                />
              </span>
            ) : (
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-white rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <Icon
                  width={26}
                  className="text-red-400"
                  icon="subway:missing"
                />
              </span>
            )}

            <div className="flex xs:flex-col xs:justify-start items-start 680px:flex-row 680px:justify-between 680px:items-start mb-4">
              <h3
                onClick={() => setShowContent(2)}
                className={clsx(
                  "cursor-pointer flex items-center mb-1 font-semibold dark:text-white tracking-[0.2px] text-xl",
                  "transition-all text-gray-700/70 hover:text-gray-800/90",
                  showContent === 2 && "transition-all text-gray-800/90"
                )}
              >
                <span>RTMP Credentials of any Live Streaming Platform</span>
                <span>
                  <Icon
                    icon="eva:arrow-ios-forward-outline"
                    width={26}
                    className={clsx(
                      "font-bold -mt-[1px] ml-3",
                      showContent === 2 ? "rotate-90" : ""
                    )}
                  />
                </span>
              </h3>
            </div>

            <animated.p
              className={clsx(
                "mb-4  font-normal text-gray-500 dark:text-gray-400 "
              )}
              style={openAnimation2}
            >
              You may add any custom RTMP Endpoint from any Service Provider or
              any Social Media Platforms. <br />
              RTMP credentials can be obtained from YouTube , Facebook Profile /
              Page / Group , Twitch , Twitter , LinkedIn , Dailymotion , TikTok
              etc.
              <br />
              <br />
              Other popular Live Streaming or Multi-streaming solutions are
              Vimeo , Stream-Yard , Restream , Dacast etc
            </animated.p>
          </li>
        </ol>
        <p
          className={clsx(
            "mb-4 text-base font-normal text-gray-500 dark:text-gray-400 transition-all"
          )}
        >
          <b>Note</b> : Our Service will work with Bigbluebutton version 2.4
          perfectly . <br />
          Bigbluebutton 2.3 will also work but Webcam & Audio Icon will be
          visible on the Stream . <br />
          Bigbluebutton 2.2 will not work with this , You may{" "}
          <a
            href="https://bigbluebutton.host/lets-talk"
            target="_blank"
            rel="noreferrer"
            className="text-secondary-100 hover:text-secondary-200 hover:underline"
          >
            contact us
          </a>
          &nbsp; if you are using 2.2 or 2.3 versions .
        </p>
      </div>
    </CustomDialog>
  );
};

export default RoadmapTimelinePopup;

/*
<Button className="py-1 px-2">
                <div className="inline-flex items-center text-sm font-medium ">
                  <svg
                    className="mr-2 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>{" "}
                  Add Server
                </div>
              </Button> */
