import React from "react";
import { useRedux } from "../../Hooks/useRedux";
import consoleHelper from "../../utils/consoleHelper";
import SpinLoader from "../SpinLoader";

const BackdropLoading = () => {
  const {
    data: {
      backdrop: { open },
    },
  } = useRedux(["backdrop"]);

  if (!open) {
    return null;
  } else {
    return (
      <>
        <div className="pointer-events-auto fixed inset-0 bg-backdropBackground flex justify-center items-center z-[999999999999999999999999999999999999]"></div>
        <div className="pointer-events-auto fixed top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] z-[999999999999999999999999999999999999]">
          <SpinLoader />
        </div>
      </>
    );
  }
};

export default BackdropLoading;
