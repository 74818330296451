import axios from "../../apis/axios";
import consoleHelper from "../../utils/consoleHelper";
import {
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_VERIFICATION_OTP,
  RESEND_SIGN_UP_VERIFICATION_OTP,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD_VERIFY_OTP,
  RESET_PASSWORD,
  LOGOUT,
  GET_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_IMAGE,
  GET_USER_PROFILE_IMAGE,
  VERIFY_UNIQUE_USER_ID,
} from "../types";

export const signUp =
  (formData, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("/user/sign-up", formData);
      dispatch({ type: SIGN_UP, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({
        type: SIGN_UP_ERROR,
        payload: { err: error?.response?.data },
      });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// verification of code after signup
export const signUpVerifyOtp =
  (formData, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("/user/verify-otp", formData);
      dispatch({ type: SIGN_UP_VERIFICATION_OTP, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({ type: SIGN_UP_ERROR, payload: error?.response?.data });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// resend code after signup
export const resendSignUpVerifyOtp =
  (formData, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("/user/resend-otp", formData);
      dispatch({ type: RESEND_SIGN_UP_VERIFICATION_OTP, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({ type: SIGN_UP_ERROR, payload: error?.response?.data });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// login user
export const login =
  (formData, navigate, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("/user/log-in", formData);
      dispatch({ type: LOGIN, payload: data });
      localStorage.setItem("dat", JSON.stringify(data.token));
      navigate("/b/dashboard");
      done && done(data?.msg);
    } catch (error) {
      dispatch({ type: SIGN_UP_ERROR, payload: error?.response?.data });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// login user
export const forgotPassword =
  (formData, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("/user/forgot-password", formData);
      dispatch({ type: FORGOT_PASSWORD, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({ type: SIGN_UP_ERROR, payload: error?.response?.data });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// verify oyp for resetting password
export const resetPasswordVerifyOtp =
  (formData, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/user/reste-password-verify-otp",
        formData
      );
      dispatch({ type: RESET_PASSWORD_VERIFY_OTP, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({ type: SIGN_UP_ERROR, payload: error?.response?.data });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };
//

// resetting password
export const resetPassword =
  (formData, { err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("/user/reset-password", formData);
      dispatch({ type: RESET_PASSWORD, payload: data });
      done && done(data?.msg);
    } catch (error) {
      dispatch({ type: SIGN_UP_ERROR, payload: error?.response?.data });
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// logout user
export const logout =
  ({ err, done }) =>
  async (dispatch) => {
    try {
      const { data } = axios.post("/user/log-out");
      dispatch({ type: LOGOUT });
      localStorage.removeItem("dat");
      localStorage.removeItem("user");
      done && done(data?.msg);
    } catch (error) {
      err && err(error?.response?.data?.err || error?.response?.data?.msg);
    }
  };

// get profile
export const getProfile =
  ({ err, done }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get("/user/profile", {
        withCredentials: true,
      });
      // if (data.err) history.push("/login");
      dispatch({ type: GET_PROFILE, payload: data });
      done && done(data);
    } catch (error) {
      err && err(error?.response?.data?.err);
    }
  };

// update user profile
export const updateUserProfile = (formData, callback) => async (dispatch) => {
  try {
    const { data } = await axios.put("/user/update-user-profile", formData);
    dispatch({ type: UPDATE_USER_PROFILE, payload: data });
    callback(null, data.msg);
  } catch (error) {
    callback(error?.response?.data, null);
  }
};

// update user profileImage
export const updateUserProfileImage =
  (formData, callback) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/user/profile-image-update",
        formData,
        {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          },
        }
      );
      dispatch({ type: UPDATE_USER_PROFILE_IMAGE, payload: data });
      callback(null, data?.msg);
    } catch (error) {
      callback(error?.response?.data, null);
    }
  };

// get user profileImage
export const getUserProfileImage = (url) => {
  return { type: GET_USER_PROFILE_IMAGE, payload: url };
};

// verify unique user id
export const verifyUniqueUserId = (id, callback) => async (dispatch) => {
  try {
    const { data } = await axios.post("/user/check-unique-userid", id);
    dispatch({ type: VERIFY_UNIQUE_USER_ID });
    callback(null, data);
  } catch (error) {
    callback(error?.response?.data, null);
  }
};
