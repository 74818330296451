import React from "react";
import Footer from "../../Footer";

const MainContentContainer = ({ children }) => {
  return (
    <div className="overflow-auto max-w-full w-full min-h-screen max-h-full flex flex-col">
      <div>{children}</div>

      <div className="flex-1" />
      {/* Footer */}
      <div className="mt-3 max-w-[1280px] w-full mx-auto">
        <Footer />
      </div>
    </div>
  );
};

export default MainContentContainer;
