import React, { useState, useEffect } from "react";
import { FormikProvider, Form, useFormik } from "formik";
import { Switch } from "@headlessui/react";
import * as Yup from "yup";

import { toast } from "react-toastify";

// actions
import {
  updateBBBServers,
  deleteBBBServers,
  getBBBServers,
  updateRTMPCred,
  getRTMPCreds,
  updateStremingCard,
  getStremingCard,
} from "../../../../../../redux/actions/RTMP-APIaction";
import { backdrop } from "../../../../../../redux/actions/helperAction";

import { useRedux } from "../../../../../../Hooks/useRedux";
import CustomDialog from "../../../../../../layouts/CustomDialog";
import CustomInput from "../../../../../../layouts/CustomInput";
import Button from "../../../../../../layouts/Button";
import consoleHelper from "../../../../../../utils/consoleHelper";

const UpdateStreamCred = ({ stream, open, closeDialog }) => {
  const {
    data: { rtmpCreds },
    dispatch,
  } = useRedux(["rtmpCreds"]);

  const [extraFormData, setExtraFormData] = useState({
    showParticipantsList: false,
    showPublicChat: false,
    hidePresentation: false,
  });

  const handleChangeExtraFormData = (name, value) => {
    setExtraFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [RTMPData, setRTMPData] = useState({
    rtmpname: "",
    rtmpurl: "",
    rtmpsecret: "",
  });
  const [RTMPid, setRTMPid] = useState("");
  let rtmpArr =
    rtmpCreds &&
    !rtmpCreds.err &&
    Array.isArray(rtmpCreds) &&
    rtmpCreds.map((r) => ({ label: r.rtmpname, value: r._id }));
  useEffect(() => {
    const rtmp =
      rtmpCreds &&
      !rtmpCreds.err &&
      Array.isArray(rtmpCreds) &&
      rtmpCreds.find((r) => {
        let _rtmp;
        if (
          r?.rtmpurl === stream?.rtmpurl &&
          r?.rtmpsecret === stream?.rtmpsecret
        ) {
          _rtmp = r;
        } else {
          _rtmp = undefined;
        }
        return _rtmp;
      });
    if (!rtmp) {
      return;
    }
    setRTMPid(() => rtmp?._id);
    setRTMPData(() => ({
      rtmpname: rtmp.rtmpname,
      rtmpurl: rtmp.rtmpurl,
      rtmpsecret: rtmp.rtmpsecret,
    }));
  }, [rtmpCreds, stream]);
  const handleChangeRTMPData = (id) => {
    setRTMPid(id);
    const rtmpCred =
      rtmpCreds &&
      !rtmpCreds.err &&
      Array.isArray(rtmpCreds) &&
      rtmpCreds.find((r) => r._id === id);

    setRTMPData(() => ({
      rtmpname: rtmpCred.rtmpname,
      rtmpurl: rtmpCred.rtmpurl,
      rtmpsecret: rtmpCred.rtmpsecret,
    }));
  };

  const INITIAL_VALUES = {
    cardname: "",
    carddescription: "",
    duration: "",
  };
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  useEffect(() => {
    setInitialValues(() => ({
      cardname: stream?.cardname,
      carddescription: stream?.carddescription,
      duration: stream?.duration,
    }));
    setExtraFormData(() => ({
      showParticipantsList: stream?.showParticipantsList,
      showPublicChat: stream?.showPublicChat,
      hidePresentation: stream?.hidePresentation,
    }));
  }, [stream]);

  const validationSchema = Yup.object().shape({
    cardname: Yup.string()
      .required("Card name is required !")
      // .matches(/^[0-9a-z]+$/, "Card name must be alpha numeric !")
      .min(3, "Card name must be minimum 3 character !")
      .max(50, "Card name must be maximum 50 character !"),
    carddescription: Yup.string()
      .max(200, "Card description max length must be 200 characters !")
      .nullable(),
    duration: Yup.number().required("Duration is required !"),
  });

  const handleUpdateStreamCard = (formData) => {
    dispatch(backdrop(true));
    dispatch(
      updateStremingCard(
        {
          ...formData,
          ...extraFormData,
          ...RTMPData,
          id: stream?._id,
        },
        {
          err: (m) => {
            dispatch(backdrop(false));
            toast.error(m);
          },
          done: (m) => {
            getStremingCard({
              err: (m) => {
                dispatch(backdrop(false));
              },
              done: (m) => {
                dispatch(backdrop(false));

                closeDialog();
              },
            });
            dispatch(backdrop(false));
            closeDialog();
            toast.success(m);
          },
        }
      )
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    // onSubmit: handleAddStreamingCard,
    onSubmit: handleUpdateStreamCard,
  });

  const { touched, errors, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      {/* <Button variant="primary" onClick={openDialog}>
        Add A bbb Server
      </Button> */}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <CustomDialog
            open={open}
            info={false}
            // onClose={() => setOpenDialog(() => false)}
            title={<span className="">Update Streaming</span>}
            button={
              <div className="mt-4 flex justify-between items-center">
                <Button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
                <Button variant="secondary" className="" onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            }
            className="w-[600px] min-w-[300px] divide-y-2 divide-gray-300"
          >
            <div className="py-4 space-y-5 max-h-[30rem] overflow-y-auto pr-2 pl-[2px]">
              <CustomInput
                title="Stream Name"
                className="w-full"
                placeholder="Stream Name..."
                autoFocus={true}
                {...getFieldProps("cardname")}
                error={Boolean(touched.cardname && errors.cardname)}
                helperText={touched.cardname && errors.cardname}
              />
              <CustomInput
                title="Stream Description"
                className="w-full"
                placeholder="Stream Description..."
                {...getFieldProps("carddescription")}
                error={Boolean(
                  touched.carddescription && errors.carddescription
                )}
                helperText={touched.carddescription && errors.carddescription}
              />
              <CustomInput
                title="Maximum Duration"
                className="w-full"
                placeholder="Maximum Streaming Duration..."
                type="number"
                {...getFieldProps("duration")}
                error={Boolean(touched.duration && errors.duration)}
                helperText={touched.duration && errors.duration}
              />

              <CustomInput
                title="RTMP Endpoint"
                className="w-full"
                selectInput
                value={RTMPid}
                dataArr={rtmpArr}
                onChange={(e) => handleChangeRTMPData(e.target.value)}
              />

              <div className="pt-1 space-y-5">
                <div className="shadow-md py-2 rounded-md">
                  <SwitchInput
                    title="Show Participants List"
                    checked={extraFormData.showParticipantsList}
                    id="ssss"
                    onChange={(checked) => {
                      handleChangeExtraFormData(
                        "showParticipantsList",
                        checked
                      );
                    }}
                  />
                </div>
                <div className="shadow-md py-2 rounded-md">
                  <SwitchInput
                    title="Show Public Chat"
                    checked={extraFormData.showPublicChat}
                    onChange={(checked) => {
                      handleChangeExtraFormData("showPublicChat", checked);
                    }}
                  />
                </div>
                <div className="shadow-md py-2 rounded-md">
                  <SwitchInput
                    title="Hide Presentation"
                    checked={extraFormData.hidePresentation}
                    onChange={(checked) => {
                      handleChangeExtraFormData("hidePresentation", checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </CustomDialog>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default UpdateStreamCred;

const SwitchInput = ({
  checked,
  onChange,
  className,
  srOnly = "Use setting",
  id,
  title,
}) => {
  return (
    <div className="flex justify-between items-center">
      {title && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-primary-200 ml-1"
        >
          {title}
        </label>
      )}
      <Switch
        id={id}
        checked={checked}
        onChange={onChange}
        // className={`${checked ? "bg-teal-900" : "bg-teal-700"}
        //   relative inline-flex flex-shrink-0 h-[24px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${className}`}
        className={`${checked ? "bg-secondary-200" : "bg-secondary-50"}
          relative inline-flex flex-shrink-0 h-[24px] w-[60px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${className}`}
      >
        <span className="sr-only">{srOnly}</span>
        <span
          aria-hidden="true"
          className={`${checked ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
        />
      </Switch>
    </div>
  );
};
