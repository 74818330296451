// Authorization: 'Bearer ' + auth.token
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ component, _key, redirectUrl, children }) => {
  if (JSON.parse(localStorage.getItem(_key)) !== null) {
    return <Navigate to={redirectUrl} />;
  } else {
    return component;
  }
  // return JSON.parse(localStorage.getItem(_key)) !== null ? (
  //   <Navigate to={redirectUrl} replace />
  // ) : (
  //   <Component />
  // );
};

export default PublicRoute;
