import {
  SHOW_SIDEBAR,
  BACKDROP,
  SHOW_SIDEBAR_NOT_FOR_INSIDE_DASHBOARD,
} from "../types";

// sidebar
export const showSidebar = (state = false, action) => {
  switch (action.type) {
    case SHOW_SIDEBAR: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// home sidebar
export const homeSidebar = (state = false, action) => {
  switch (action.type) {
    case SHOW_SIDEBAR_NOT_FOR_INSIDE_DASHBOARD: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

// backdrop
const INITIAL_BACKDROP_STATE = {
  open: false,
  // component: null,
};
export const backdrop = (state = INITIAL_BACKDROP_STATE, action) => {
  switch (action.type) {
    case BACKDROP: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
