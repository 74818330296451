import React from "react";
import clsx from "clsx";

const Button = ({
  variant,
  color,
  href,
  children,
  type = "",
  className,
  disabled = false,
  onClick = () => {},
  ...rest
}) => {
  // function handleClickRipple(e) {
  //   // Create span element
  //   let ripple = document.createElement("span");

  //   // Add ripple class to span
  //   ripple.classList.add("ripple");

  //   // Add span to the button
  //   this.appendChild(ripple);

  //   // Get position of X
  //   let x = e.clientX - e.target.offsetLeft;

  //   // Get position of Y
  //   let y = e.clientY - e.target.offsetTop;

  //   // Position the span element
  //   ripple.style.left = `${x}px`;
  //   ripple.style.top = `${y}px`;

  //   // Remove span after 0.3s
  //   setTimeout(() => {
  //     ripple.remove();
  //   }, 300);
  // }

  let button;

  switch (variant) {
    case "primary": {
      if (href) {
        button = (
          <a
            className={clsx("btn-primary", className)}
            href={href}
            onClick={disabled ? () => {} : onClick}
            {...rest}
          >
            {children}
          </a>
        );
      } else {
        button = (
          <button
            className={clsx(
              "btn-primary",
              disabled
                ? "bg-primary-100/40 text-gray-50 after:absolute after:inset-0  after:text-gray-900 cursor-default"
                : "hover:bg-primary-200",
              className
            )}
            type={type}
            onClick={disabled ? () => {} : onClick}
            {...rest}
          >
            {children}
          </button>
        );
      }
      break;
    }
    case "secondary": {
      if (href) {
        button = (
          <a
            className={clsx(
              "btn-secondary",
              disabled
                ? "after:absolute after:inset-0 after:bg-gray-500/60 after:text-gray-900 cursor-default"
                : "hover:bg-secondary-200",
              className
            )}
            href={href}
            onClick={disabled ? () => {} : onClick}
            {...rest}
          >
            {children}
          </a>
        );
      } else {
        button = (
          <button
            className={clsx(
              "btn-secondary",
              disabled
                ? "bg-secondary-100/40 text-gray-50 after:absolute after:inset-0 after:text-gray-900 cursor-default"
                : "hover:bg-secondary-200",
              className
            )}
            type={type}
            onClick={disabled ? () => {} : onClick}
            {...rest}
          >
            {children}
          </button>
        );
      }
      break;
    }

    default: {
      if (href) {
        button = (
          <a
            className={clsx("btn", className)}
            href={href}
            onClick={onClick}
            {...rest}
          >
            {children}
          </a>
        );
      } else {
        button = (
          <button
            className={clsx("btn", className)}
            type={type}
            onClick={onClick}
            {...rest}
          >
            {children}
          </button>
        );
      }
      break;
    }
  }
  return button;
};

export default Button;

const OtherButtons = () => {
  return (
    <>
      {/* Basic Primary Buttons */}
      <a
        href="#"
        className="bg-white hover:bg-gray-200 text-black text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-black hover:bg-gray-900 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-gray-500 hover:bg-gray-700 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-red-500 hover:bg-red-700 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-yellow-500 hover:bg-yellow-700 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-green-500 hover:bg-green-700 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-blue-500 hover:bg-blue-700 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-indigo-500 hover:bg-indigo-700 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-purple-500 hover:bg-purple-700 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-pink-500 hover:bg-pink-700 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>

      {/* Outlined Secondary Buttons */}
      <a
        href="#"
        className="bg-transparent hover:bg-white border border-bg-white text-white hover:text-black text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-black text-black hover:bg-black hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-red-500 text-red-500 hover:bg-red-500 hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-green-500 text-green-500 hover:bg-green-500 hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-indigo-500 text-indigo-500 hover:bg-indigo-500 hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-purple-500 text-purple-500 hover:bg-purple-500 hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-transparent border border-pink-500 text-pink-500 hover:bg-pink-500 hover:text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>

      {/* Light Buttons */}
      <a
        href="#"
        className="bg-white hover:bg-gray-200 text-black text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-gray-200 hover:bg-gray-500 hover:text-white text-gray-500 text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-red-200 hover:bg-red-500 hover:text-white text-red-500 text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-yellow-200 hover:bg-yellow-500 hover:text-white text-yellow-500 text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-green-200 hover:bg-green-500 hover:text-white text-green-500 text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-blue-200 hover:bg-blue-500 hover:text-white text-blue-500 text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-indigo-200 hover:bg-indigo-500 hover:text-white text-indigo-500 text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-purple-200 hover:bg-purple-500 hover:text-white text-purple-500 text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-pink-200 hover:bg-pink-500 hover:text-white text-pink-500 text-center py-2 px-4 rounded"
      >
        Button
      </a>

      {/* Buttons with Icons */}
      <a
        href="#"
        className="bg-white hover:bg-gray-200 text-black py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-black hover:bg-gray-900 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-yellow-500 hover:bg-yellow-700 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-indigo-500 hover:bg-indigo-700 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>
      <a
        href="#"
        className="bg-pink-500 hover:bg-pink-700 text-white py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
        Button
      </a>

      {/* Rounded Buttons */}
      <a
        href="#"
        className="bg-white hover:bg-gray-200 text-black text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-black hover:bg-gray-900 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-gray-500 hover:bg-gray-700 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-red-500 hover:bg-red-700 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-yellow-500 hover:bg-yellow-700 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-green-500 hover:bg-green-700 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-blue-500 hover:bg-blue-700 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-indigo-500 hover:bg-indigo-700 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-purple-500 hover:bg-purple-700 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-pink-500 hover:bg-pink-700 text-white text-center py-2 px-4 rounded-full"
      >
        Button
      </a>

      {/* Circular Buttons */}
      <a
        href="#"
        className="bg-white hover:bg-gray-200 text-black text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-black hover:bg-gray-900 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-gray-500 hover:bg-gray-700 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-red-500 hover:bg-red-700 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-yellow-500 hover:bg-yellow-700 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-green-500 hover:bg-green-700 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-blue-500 hover:bg-blue-700 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-indigo-500 hover:bg-indigo-700 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-purple-500 hover:bg-purple-700 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>
      <a
        href="#"
        className="bg-pink-500 hover:bg-pink-700 text-white text-center py-2 px-4 rounded-full h-14 w-14 inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </a>

      {/* 3D Buttons */}
      <a
        href="#"
        className="bg-gray-200 hover:bg-gray-100 border-b-4 border-gray-400 hover:border-gray-200 text-black text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-gray-800 hover:bg-gray-700 border-b-4 border-black hover:border-gray-800 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-gray-500 hover:bg-gray-400 border-b-4 border-gray-700 hover:border-gray-500 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-red-500 hover:bg-red-400 border-b-4 border-red-700 hover:border-red-500 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-yellow-500 hover:bg-yellow-400 border-b-4 border-yellow-700 hover:border-yellow-500 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-green-500 hover:bg-green-400 border-b-4 border-green-700 hover:border-green-500 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-blue-500 hover:bg-blue-400 border-b-4 border-blue-700 hover:border-blue-500 text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-indigo-500 hover:bg-indigo-400 border-b-4 border-indigo-700 hover:border-indigo-500 text-white text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-purple-500 hover:bg-purple-400 border-b-4 border-purple-700 hover:border-purple-500 text-white text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
      <a
        href="#"
        className="bg-pink-500 hover:bg-pink-400 border-b-4 border-pink-700 hover:border-pink-500 text-white text-white text-center py-2 px-4 rounded"
      >
        Button
      </a>
    </>
  );
};
