import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivetRoute = ({ component, _key, redirectUrl }) => {
  if (JSON.parse(localStorage.getItem(_key)) !== null) {
    return component;
  } else {
    return <Navigate to={redirectUrl} />;
  }
};

export default PrivetRoute;
