import { combineReducers } from "redux";

// helper reducers
import { showSidebar, backdrop, homeSidebar } from "./helperReducer";

// auth reducer
import { userReducer, getUserProfileImage } from "./authReducer";

// RTMP API
import {
  getAPIS,
  getBBBServers,
  getRTMPCreds,
  getRunningRooms,
  getStreamingCards,
  getRunningStreaming,
  getPastStreamings,
} from "./RTMP-API-Reducer";

import { getAllPlans, getOnePlan } from "./plans";

import {
  createStripePaymentIntent,
  openBillingAddressPage,
  localRechargeDetails,
  stripeBillingDetails,
  getCurencyConversionRateInUSD,
} from "./stripePayment";

const reducers = combineReducers({
  // helper
  sidebar: showSidebar,
  homeSidebar: homeSidebar,
  backdrop,

  // auth
  user: userReducer,
  userProfileImage: getUserProfileImage,

  // RTMP APIS
  myApi: getAPIS,

  // BBB Servers
  bbbServers: getBBBServers,

  // BBB Servers
  rtmpCreds: getRTMPCreds,

  // get all running meeting rooms
  runningMeetings: getRunningRooms,

  // streaming cards
  streamingCards: getStreamingCards,

  // running streaming
  runningStreaming: getRunningStreaming,

  // past streamings
  pastStreamings: getPastStreamings,

  // plans
  allPlans: getAllPlans,
  singlePlan: getOnePlan,

  // stripe payments
  showBillingAddressPage: openBillingAddressPage,
  stripeClientSecret: createStripePaymentIntent,
  localLastRecharge: localRechargeDetails,
  stripeBillingDetails,
  INRinUSD: getCurencyConversionRateInUSD,
});

export default reducers;
