import { useEffect, useState } from "react";

import Button from "../../../../../../layouts/Button";
import More from "./More";
import {
  getOneRunningStreaming,
  getStremingCard,
  singleStream,
  startStreaming,
  stopStreaming,
} from "../../../../../../redux/actions/RTMP-APIaction";
import { backdrop } from "../../../../../../redux/actions/helperAction";
import { useRedux } from "../../../../../../Hooks/useRedux";
import consoleHelper from "../../../../../../utils/consoleHelper";
import DotLoader from "../../../../../../layouts/DotLoader";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

// const disablKey = "hdbh^%&6GhGU&*&6yug*&^%&^*TGyuiGU&^Z78UIHiu*&";

const SingleStreamCard = ({ stream }) => {
  const {
    data: { runningStreaming },
    dispatch,
  } = useRedux(["runningStreaming"]);

  const disablKey = `disableStartBtn-${stream?._id}`;
  // const disabledStartBtn = JSON.parse(localStorage.getItem(disablKey));
  const [disabledStartBtn, setDisabledStartBtn] = useState(
    JSON.parse(localStorage.getItem(disablKey))
  );
  useEffect(() => {
    setDisabledStartBtn(JSON.parse(localStorage.getItem(disablKey)));
  }, []);

  const run_stream = stream && stream?.liveStreamResponse;

  consoleHelper("run_stream", run_stream);
  // consoleHelper("stream", stream);

  // useEffect(() => {
  //   dispatch(singleStream({ id: stream?._id }, {}));
  // }, []);

  // get stream status
  function refreashStream({ err, done }) {
    dispatch(
      singleStream(
        { id: stream?._id },
        {
          err: (m) => err(m),
          done: (m) => done(m),
        }
      )
    );
  }

  const [initializing, setInitializing] = useState(false);
  const initializingKey = `initializing-${stream?._id}`;

  const handleStartStreaming = () => {
    localStorage.setItem(disablKey, JSON.stringify(true));
    setDisabledStartBtn(JSON.parse(localStorage.getItem(disablKey)));

    dispatch(backdrop(true));
    dispatch(
      startStreaming(
        {
          id: stream?._id,
          runningMeetingId: stream?.runningMeetingId,
          showParticipantsList: stream?.showParticipantsList,
          showPublicChat: stream?.showPublicChat,
          hidePresentation: stream?.hidePresentation,
          duration: stream?.duration,
        },
        {
          err: (m) => {
            dispatch(backdrop(false));
            toast.error(m);
            localStorage.removeItem(disablKey);
            setDisabledStartBtn(JSON.parse(localStorage.getItem(disablKey)));
          },
          done: (m) => {
            refreashStream({
              err: (m) => {
                dispatch(backdrop(false));
              },
              done: () => {
                localStorage.setItem(
                  initializingKey,
                  JSON.stringify(Date.now())
                );
                setInitializing(() => true);
                dispatch(backdrop(false));
                setTimeout(() => {
                  setInitializing(() => false);
                  localStorage.removeItem(initializingKey);
                  localStorage.removeItem(disablKey);
                  setDisabledStartBtn(
                    JSON.parse(localStorage.getItem(disablKey))
                  );
                  toast.success(m);
                }, 90000);
              },
            });
          },
        }
      )
    );
  };

  useEffect(() => {
    const initializationTime = localStorage.getItem(initializingKey);
    const remainingTime = Date.now() - JSON.parse(initializationTime);

    let timeout;

    if (initializationTime !== null) {
      if (remainingTime < 90000) {
        setInitializing(() => true);
        localStorage.setItem(disablKey, JSON.stringify(true));
        setDisabledStartBtn(JSON.parse(localStorage.getItem(disablKey)));

        timeout = setTimeout(() => {
          setInitializing(() => false);

          localStorage.removeItem(initializingKey);
          localStorage.removeItem(disablKey);
          setDisabledStartBtn(JSON.parse(localStorage.getItem(disablKey)));
        }, 90000 - remainingTime);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleStopStreaming = () => {
    dispatch(backdrop(true));
    //    const { runningMeetingId, streamId } = req.body;
    dispatch(
      stopStreaming(
        {
          runningMeetingId: stream?.runningMeetingId,
          streamId: run_stream?.serverId,
          id: stream?._id,
        },
        {
          err: (m) => {
            dispatch(backdrop(false));
            toast.error(m);
          },
          done: (m) => {
            refreashStream({
              err: (m) => {
                dispatch(backdrop(false));
              },
              done: (m) => {
                dispatch(backdrop(false));
              },
            });
            dispatch(backdrop(false));
            toast.success(m);
          },
        }
      )
    );
  };

  return (
    <div className="relative shadow border-1 rounded-lg overflow-hidden max-w-[500px]">
      <div className="absolute top-2 right-2">
        <More stream={stream} />
      </div>
      <div className="bg-white w-full p-1 pb-3 divide-y divide-gray-200 ">
        {/* Stream name */}
        <div className="flex items-center p-1 pl-3 rounded-lg w-full ">
          <span className="flex">
            <h1 className="text-secondary-100 text-xl font-semibold tracking-wide max-w-[400px] truncate">
              {stream?.cardname}
            </h1>
          </span>
        </div>
        {/*  */}

        {/* <Row
          title="Description :"
          value={stream?.carddescription}
          icon={
            <Icon
              icon="openmoji:details"
              width={22}
              className="text-primary-50 font-semibold"
            />
          }
        /> */}
        <Row
          title="Meeting Name :"
          value={stream?.runningmeetingName}
          icon={
            <Icon
              icon="openmoji:details"
              width={22}
              className="text-primary-50 font-semibold"
            />
          }
        />
        <Row
          title="RTMP :"
          value={stream?.rtmpname}
          icon={
            <Icon
              icon="openmoji:details"
              width={22}
              className="text-primary-50 font-semibold"
            />
          }
        />

        {/* <Row
          title="Status :"
          value={
            initializing === true ? (
              <div className="flex items-center gap-2 justify-center">
                <span className="font-medium">initializing</span>
                <DotLoader />
              </div>
            ) : run_stream?.serverId ? (
              <span className="font-medium text-green-500">running</span>
            ) : (
              <span className="font-medium text-red-400">stopped</span>
            )
          }
        /> */}
        <Row
          title="Max Duration :"
          value={
            <div>
              <span className="font-medium text-sm">{stream?.duration}</span>
              &nbsp;
              <span className="font-medium text-lg">min</span>
            </div>
          }
          icon={
            <Icon
              icon="ic:baseline-access-time"
              width={22}
              className="text-primary-50 font-semibold"
            />
          }
        />

        <div className="pt-3 px-3 w-full flex justify-between items-center">
          <div>
            {!run_stream || run_stream === null || !run_stream?.serverId ? (
              <Button
                variant="secondary"
                className="min-w-[116px]"
                onClick={
                  disabledStartBtn === true ? () => {} : handleStartStreaming
                }
                disabled={disabledStartBtn === true}
              >
                Start
              </Button>
            ) : initializing ? (
              <Button className="min-w-[116px] pointer-events-none">
                <div className="flex items-center gap-2 justify-center">
                  <span>initializing</span>
                  <DotLoader />
                </div>
              </Button>
            ) : (
              <Button
                variant="primary"
                className="min-w-[116px]"
                onClick={handleStopStreaming}
              >
                Stop
              </Button>
            )}
          </div>

          <div>
            {initializing === true ? (
              <div className="flex items-center gap-2 justify-center">
                <span className="font-medium">initializing</span>
                <DotLoader />
              </div>
            ) : run_stream?.serverId ? (
              <span className="font-medium text-green-500">running</span>
            ) : (
              <span className=""></span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleStreamCard;

const Row = ({
  title,
  value,
  icon = (
    <Icon
      icon="fluent:live-24-filled"
      width={22}
      className="text-primary-50 font-semibold"
    />
  ),
}) => {
  return (
    <div className="flex items-center justify-between p-1 pl-3 rounded-lg w-full text-xs ">
      <span className="min-w-[8rem] flex items-center gap-2">
        {icon}
        <span className="text-gray-500 font-semibold">{title}</span>
      </span>
      <div className="text-gray-600 block max-w-[300px] truncate">{value}</div>
    </div>
  );
};
